.player-section .left-bar,
.player-section .left-bar-placeholder,
.player-section .right-side {
    display: none !important;
}

.virtual-board.player-section {
    height: var(--view-height);
    overflow: visible;
    width: calc(100% - 2vh);
    margin: 1vh;
    /*border-radius: var(--main-ui-border-radius);*/
    --view-height: calc(100vh - var(--tumulte-height) - 2vh) !important;
    /*--bottom-section-height: 3vh !important;*/
}

.spectating .virtual-board.player-section .deck,
.spectating .virtual-board.player-section .mana-hidden,
.virtual-board.player-section.opponent .hand *,
.virtual-board.player-section.opponent .deck,
.virtual-board.player-section.opponent .mana {
    pointer-events: none !important;
}

.spectating .virtual-board.player-section .hand,
.virtual-board.player-section.opponent .hand {
    top: -3vh !important;
    transform: scale(0.8);
}
/*
.spectating .virtual-board.player-section .hand .tapped-wrapper:hover {
    transform: scale(1);
    transform-origin: bottom;
    margin-left: 0;
    margin-right: 0;
    top: 12vh !important;
    flex: 1 !important;
    min-width: 1vh !important;
    filter: brightness(0.7);
}

.spectating .virtual-board.player-section .hand .tapped-wrapper:last-child:hover {
    flex: 5 !important;
    min-width: calc(25vh / var(--card-size-ratio)) !important;
}*/

.virtual-board.player-section .fullscreen-panel.discard-panel {
    position: relative;
    top: -100%;
    width: 100%;
    height: 100%;
    z-index: 500;
    border-radius: var(--main-ui-border-radius);
    overflow: hidden;
}

.virtual-board.player-section.opponent .mana-count .button {
    display: none;
}