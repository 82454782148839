.power-counters {
    position: absolute;
    width: calc(var(--tumulte-card-width) * 5);
    height: var(--tumulte-height);
    left: 0;
    right: 0;
    top: 1vh;
    margin-left: auto;
    margin-right: auto;
    --counter-width: 3.5vh;
}

.power-counters .power-counter {
    width: calc(var(--counter-width) * 3);
    height: 2vh;
    position: absolute;
    z-index: 100;
    transition: all 0.2s;
}

.power-counters .power-counter.player:hover {
    transform: scale(1.5);
}

/*
.power-counters .power-counter.hovered {
    transition: all 0s !important;
    transform: scale(1.4);
}*/

.power-counters .wrapper {
    background-color: var(--secondary-background-color);
    width: calc(100% + 1vh) !important;
    height: calc(100% + 1vh);
    position: absolute;
    top: -0.5vh;
    left: -0.5vh;
    display: none;
}

.power-counters .power-counter.hero {
    left: -1vh;
}

.power-counters .power-counter.companion {
    right: -1vh;
}

.power-counters .power-counter.player {
    bottom: 1.5vh;
}

.power-counters .power-counter.opponent {
    top: -0.5vh;
}


.power-counters .power-counter>.counter {
    width: var(--counter-width);
    padding: 0.5vh;
    position: relative;
}

.power-counters .power-counter>.counter.inactive .background {
    background-color: var(--secondary-background-color) !important;
}

.power-counters .power-counter>.counter p {
    font-weight: var(--font-weight-semi-bold);
    color: white;
    margin: 0;
    font-size: var(--small-font-size);
    z-index: 4;
}

.power-counters .power-counter>.counter.winning {
    box-shadow: 0 -0.5vh 0 var(--main-color), 0 0.5vh 0 var(--main-color),
    0 -0.6vh 0.8vh var(--main-color), 0 0.6vh 0.8vh var(--main-color);
}

.power-counters .power-counter>.counter img {
    height: 1vh;
    z-index: 3;
}

.power-counters .power-counter>.counter.forest img {
    transform: translateX(-0.1vh);
}

.power-counters .power-counter .button {
    transition: all 0s;
}

.power-counters .power-counter>.counter .button {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
    height: 2vh;
    opacity: 0;
    font-size: var(--small-font-size);
    transition: transform 0.2s;
}

.power-counters .power-counter:hover>.counter .button.minus {
    transform: translateY(2vh);
}

.power-counters .power-counter:hover>.counter .button.plus {
    transform: translateY(-2vh);
}

.power-counters .power-counter>.counter:hover .button.minus {
    opacity: 1;
    bottom: auto;
}

.power-counters .power-counter>.counter:hover .button.plus {
    opacity: 1;
    top: auto;
}

.power-counters .power-counter>.counter .background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
}

.power-counters .power-counter>.counter.forest .background {
    background-color: #9EB042;
}

.power-counters .power-counter>.counter.mountain .background {
    background-color: #BD8049;
}

.power-counters .power-counter>.counter.water .background {
    background-color: #5871A6;
}

.power-counters .power-counter>.counter.inactive.forest p {
    color: #9EB042;
}
.power-counters .power-counter>.counter.inactive.mountain p {
    color: #BD8049;
}
.power-counters .power-counter>.counter.inactive.water p {
    color: #5871A6;
}
.power-counters .power-counter>.counter.inactive.forest img {
    filter: brightness(0) saturate(100%) invert(62%) sepia(6%) saturate(4160%) hue-rotate(30deg) brightness(109%) contrast(80%);
}
.power-counters .power-counter>.counter.inactive.mountain img {
    filter: brightness(0) saturate(100%) invert(60%) sepia(16%) saturate(1536%) hue-rotate(347deg) brightness(88%) contrast(82%);
}
.power-counters .power-counter>.counter.inactive.water img {
    filter: brightness(0) saturate(100%) invert(43%) sepia(13%) saturate(1596%) hue-rotate(183deg) brightness(98%) contrast(87%);
}

.power-counters .power-counter .all {
    position: absolute;
    right: -4vh;
    width: 4vh;
    height: 2vh;
    top: 0;
    z-index: 2;
}

.power-counters .power-counter.hero .all {
    left: -4vh;
    right: auto;
}

.power-counters .power-counter:hover .all .button {
    opacity: 1;
}





.mode2v2 .power-counters.fullscreen {
    width: 0 !important;
    height: 0 !important;
    background-color: red;
    top: 50%;
    left: 50%;
    z-index: 1000000;
    margin: 0 !important;
}

.mode2v2 .fullscreen .power-counter {
    transform: translate(-50%, -50%);
}

.mode2v2 .power-counters.fullscreen .power-counter.player:hover {
    transform: translate(-50%, -50%) scale(1.4);
}

.mode2v2 .fullscreen .player-1 .hero {
    left: calc(var(--tumulte-card-width) * -2);
    top: calc(var(--tumulte-card-height) / -1.2);
}

.mode2v2 .fullscreen .player-2 .hero {
    left: calc(var(--tumulte-card-width) * 2);
    top: calc(var(--tumulte-card-height) / -1.2);
}

.mode2v2 .fullscreen .player-4 .hero {
    left: calc(var(--tumulte-card-width) * -2);
    top: calc(var(--tumulte-card-height) / 1.2);
}

.mode2v2 .fullscreen .player-3 .hero {
    left: calc(var(--tumulte-card-width) * 2);
    top: calc(var(--tumulte-card-height) / 1.2);
}

.mode2v2 .fullscreen .player-1 .companion {
    left: calc(var(--tumulte-card-height) / -0.8);
    top: calc(var(--tumulte-card-width) * -2);
}

.mode2v2 .fullscreen .player-2 .companion {
    left: calc(var(--tumulte-card-height) / 0.8);
    top: calc(var(--tumulte-card-width) * -2);
}

.mode2v2 .fullscreen .player-3 .companion {
    left: calc(var(--tumulte-card-height) / 0.8);
    top: calc(var(--tumulte-card-width) * 2);
}

.mode2v2 .fullscreen .player-4 .companion {
    left: calc(var(--tumulte-card-height) / -0.8);
    top: calc(var(--tumulte-card-width) * 2);
}

.mode2v2 .player-opponent-counter {
    scale: 1.5;
}