.QRCode {
    padding: 2vh;
    background-color: white;
    border-radius: 2vh;
    width: 40vh;
    margin-left: auto;
    margin-right: auto;
}

.QRCode-area {
    /*width: fit-content;*/
}

.phone-setup-video {
    max-width: 80vw;
    margin-left: auto;
    margin-right: auto;
}

.player-preview {
    background-color: var(--white-background-color);
    /*border: 1px solid black;*/
    border-radius: var(--main-ui-border-radius);
    height: fit-content;
    min-height: 40vh;
    width: 100%;
    overflow: hidden;
    position: relative;
}

.player-preview img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
}

.player-preview video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
    top: 100%;
    left: 0;
}

.player-preview>div:nth-child(2) {
    position: relative;
    top: -100%;
}

.player-preview .guidelines {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 100;
}

.player-preview .guidelines .guideline {
    position: relative;
    left: 0;
    height: 0.7vh;
    background: var(--white-background-color);
    content: "";
    width: 100%;
    display: block;
}

.player-preview .guidelines .guideline:nth-child(1) {
    top: 33%;
}

.player-preview .guidelines .guideline:nth-child(2) {
    top: 66%;
}