.pull-handle {
    width: 100%;
    height: 1vh;
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    cursor: n-resize;
}

.pull-handle.dragging {
    border-top: solid 0.2vh var(--main-color);
}

/*----- GLASS BACKGROUND -----*/

.glass-background {
    backdrop-filter: blur(2vh);
    -webkit-backdrop-filter: blur(2vh);
    z-index: -1;
    height: 100%;
    width: 100%;
}

.glass-background>div {
    background-color: var(--background-color);
    opacity: 0.4;
    height: 100%;
    width: 100%;
}

.left-bar .glass-background {
    width: calc(100% + var(--side-bar-toggle-width));
}

/*----- VIRTUAL SHORTCUT MEMO -----*/

.virtual-shortcuts-memo {
    background-color: var(--background-color);
    border-radius: var(--main-ui-border-radius);
    padding: 1.5vh;
}

.virtual-shortcuts-memo hr {
    margin: 0.2vh;
}

.virtual-shortcuts-memo h2 {
    margin: 0;
    font-size: var(--main-font-size);
    text-align: start;
}

.virtual-shortcuts-memo p {
    margin: 0;
    font-size:  var(--small-font-size);
    text-align: start;
}

.virtual-shortcuts-memo p:first-child {
    font-weight: var(--font-weight-semi-bold);
}
