.fullscreen-panel {
    width: 100%;
    height: 100%;
    backdrop-filter: blur(var(--main-blur));
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9000000;
}

.fullscreen-panel .dismiss-area {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9001;
    opacity: 0.3;
    background-color: var(--secondary-background-color);
}

.fullscreen-panel .fullscreen-panel-content {
    position: relative;
    max-width: 66.666667vw;
    padding: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10vh;
    margin-bottom: 5vh;
    border-radius: var(--main-ui-border-radius);
    overflow: hidden;
    z-index: 9002;
    max-height: 80vh;
}

.fullscreen-panel .fullscreen-panel-content .title-area {
    background-color: var(--grey-background-color);
    padding: 2vh;
}

.fullscreen-panel .fullscreen-panel-content .title-area h2 {
    margin: 0;
}

.fullscreen-panel .fullscreen-panel-content .title-area img {
    filter: brightness(var(--img-brightness));
    width: var(--main-font-size-subtitle);
    height: var(--main-font-size-subtitle);
    margin-top: auto;
    margin-bottom: auto;
}

.fullscreen-panel .fullscreen-panel-content .title-area img:hover {
    opacity: 0.7;
    cursor: pointer;
}

.fullscreen-panel .fullscreen-panel-content .content-area {
    background-color: var(--white-background-color);
    padding: 5vh;
    overflow-y: auto;
}

.fullscreen-panel .fullscreen-panel-content p {
    text-align: start;
}

.fullscreen-panel .fullscreen-panel-content .content-area h3 {
    margin-top: 1vh;
}

.fullscreen-panel .fullscreen-panel-content button {
    margin-left: 2vh;
    margin-right: 2vh;
}

.close-button {
    pointer-events: all !important;
}