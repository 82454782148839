.mode2v2 .player-game-sections:not(.focused) .virtual-board {
    --pile-count-font-size: 3vh;
}
.mode2v2 .player-game-section.your-section {
    /*z-index: 1000000;*/
}

.mode2v2 .not-focused .power-counters {
    width: 70%;
    height: 0;
    pointer-events: none;
    position: absolute;
    top: calc(var(--top-bar-height));
    left: 0;
}

.mode2v2 .player-game-section:has(.tokens-panel:hover) .power-counters {
    opacity: 0 !important;
}

.mode2v2 .player-game-sections.focused .power-counters .power-counter {
    top: auto;
    bottom: 7.5vh;
}

.mode2v2 .not-focused .power-counter {
    pointer-events: all;
    /*z-index: 90002;*/
    top: 0 !important
}

.mode2v2 .player-game-section:has(.fullscreen-panel) .power-counters {
    opacity: 0 !important;
}

.mode2v2 .player-game-sections:not(.focused) .virtual-board {
    --card-height: calc(var(--view-height) / 3.4 - var(--border-width) * 2 - 2vh);
    --section-height: calc(var(--card-height) + 1vh + var(--border-width));
}

.mode2v2 .not-focused .virtual-board .board {
    padding-top: 1vh !important;
    padding: 0.1vh;
}

.mode2v2 .not-focused .virtual-board .board-section {
    margin: 0.3vh;
    padding: 1vh;
}

.mode2v2 .player-game-sections:not(.focused) .virtual-board .hand {
    --hand-card-height: 20vh !important;
    top: -9.5vh;
    max-width: calc(100% - 34vh);
}

.mode2v2 .player-game-sections:not(.focused) .virtual-board.opponent .hand {
    top: -9.5vh !important;
}

.mode2v2 .player-game-sections:not(.focused) .virtual-board .hand .tapped-wrapper {
    --hand-appear-translate-y: -26vh !important;
}

.mode2v2 .not-focused .virtual-board .hand .tapped-wrapper.selected,
.mode2v2 .not-focused .virtual-board .hand .tapped-wrapper:hover {
    margin-left: 2.2vh !important;
    margin-right: 2.2vh !important;
}

.mode2v2 .player-game-section .first-player-image {
    /*z-index: 9000000;*/
}

.mode2v2 .not-focused .virtual-board .button,
.mode2v2 .not-focused .virtual-board a {
    height: 3.5vh !important;
    font-size: 1.5vh !important;
}

.mode2v2 .not-focused .small-round-button, 
.mode2v2 .not-focused .virtual-board .focus-card-button {
    width: 3vh;
    height: 3vh;
    padding: 0.7vh;
    margin-left: 0.3vh;
}

.mode2v2 .not-focused .deck {
    margin-left: auto !important;
}

.mode2v2 .not-focused .mana {
    top: auto;
    bottom: 0vh;
    left: 0vh;
    height: calc(var(--card-height) + 2.5vh);
    margin: 0 !important;
}

.mode2v2 .not-focused .mana .button {
    height: 2.8vh !important;
}

.mode2v2 .use-mini-cards .board-section.reserve,
.mode2v2 .use-mini-cards .board-section.landmarks,
.mode2v2 .use-mini-cards .board-section.hero-expedition,
.mode2v2 .use-mini-cards .board-section.companion-expedition,
.mode2v2 .use-mini-cards .board-section.hero {
    --card-height: calc(var(--view-height) / 2.75 - 3vh - var(--border-width)) !important;
    --card-width: 13vh !important;
    --section-height: calc(var(--card-height) + 2vh + var(--border-width)) !important;
}

.mode2v2 .not-focused .discard-panel {
    padding-top: 2vh;
    border-radius: 0 !important;
    padding-bottom: 0vh;
}

.mode2v2 .not-focused .discard-panel .close-button {
    width: 3vh;
}

.mode2v2 .not-focused .discard-panel h2 {
    margin-bottom: 0vh !important;
}

.mode2v2 .not-focused .discard-panel>.flex-column {
    height: 100%;
}

.mode2v2 .not-focused .discard-panel .flex-row {
    padding-bottom: 0vh;
    overflow-y: hidden;
    height: 100%;
}

.mode2v2 .not-focused .deck .interaction a {
    margin-left: -1vh;
    margin-right: -1vh;
    margin-bottom: 0;
}
.mode2v2 .not-focused .deck .interaction a:nth-child(2) {
    margin-top: 0.5vh;
}

.mode2v2 .not-focused .extra-toggler {
    width: 2.8vh;
    height: 2.8vh;
}

.mode2v2 .not-focused .extra-toggler p {
    font-size: 2.1vh;
}

.mode2v2 .not-focused .extra-content {
    left: -14vh;
}

.mode2v2 .not-focused .card-focused .tapped-wrapper {
    --card-focused-section-width: 30vh !important;
}

.mode2v2 .quick-tokens-info {
    display: none !important;
}