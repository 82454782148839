.virtual-board.streamer-layout .board-sections>div {
    margin-left: 22vh;
}

.virtual-board.streamer-layout .board-sections>div:last-child {
    margin-left: 0;
    position: absolute;
    top: 5vh;
    left: 0;
    height: calc(var(--section-height) * 2 + 1.5vh);
    width: 20vh;
    flex-direction: column !important;
}

.virtual-board.streamer-layout .mana {
    height: 100%;
}

.virtual-board.streamer-layout .mana .scrollable {
    flex-direction: column !important;
    padding-top: 0.5vh;
    padding-left: 0.5vh;
}

.virtual-board.streamer-layout .mana .scrollable .tapped-wrapper:first-child .board-card {
    margin-top: 0 !important;
}

.virtual-board.streamer-layout .mana .scrollable .tapped-wrapper .board-card {
    width: 8vh !important;
    height: 11.2vh !important;
    margin-right: 0;
    margin-top: -8vh !important;
    border-radius: 1vh;
    transform: translateY(8vh);
}
.virtual-board.streamer-layout .board-section.mana .scrollable .tapped-wrapper .board-card:hover {
    min-width: 8vh !important;
}

.virtual-board.streamer-layout .mana .scrollable .board-card>img {
    width: 100%;
    height: 100%;
    border-radius: 0.5vh !important;
}

.virtual-board.streamer-layout .mana .scrollable .tapped-wrapper.tapped .board-card {
    margin-right: 0;
    transform: rotate(0deg) translateX(-4vh) translateY(8vh) !important;
}

.virtual-board.streamer-layout .deck {
    border: 0 !important;
    background-color: transparent;
}

.virtual-board.streamer-layout .discard {
    margin-bottom: 0 !important;
    min-width: 0 !important;
    max-width: calc(var(--card-width) + 0.5vh) !important;
    margin-left: 1vh;
}

.virtual-board.streamer-layout .discard,
.virtual-board.streamer-layout .deck {
    min-height: 10vh;
    height: 10vh;
    min-width: 20vh;
    max-width: 20vh;
    margin-top: 0;
}

.virtual-board.streamer-layout .deck .board-card,
.virtual-board.streamer-layout .discard .board-card {
    height: 9.25vh;
}

.virtual-board.streamer-layout .discard .board-card img {
    height: var(--card-height);
}

.virtual-board.streamer-layout .deck .interaction {
    opacity: 1 !important;
    padding: 0 !important;
    padding-left: 4vh !important;
    padding-right: 2vh !important;
}

.virtual-board.streamer-layout .deck img {
    display: none;
}

.virtual-board.streamer-layout .deck .interaction a {
    margin: 0 !important;
}

.virtual-board.streamer-layout .deck,
.virtual-board.streamer-layout .deck .board-card {
    overflow: visible;
    border-radius: 0 !important;
}

.virtual-board.streamer-layout .deck .pile-count {
    display: none;
}

.virtual-board.streamer-layout .deck .interaction a:first-child {
    margin-bottom: 0.25vh !important;
    margin-top: 0.25vh !important;
}

.virtual-board.streamer-layout .deck .extra {
    right: auto;
    left: 0;
}

.virtual-board.streamer-layout .hand {
    bottom: 10vh;
    top: auto;
}

.virtual-board.streamer-layout .hand .tapped-wrapper {
    top: auto !important;
    bottom: 8vh;
}

.virtual-board.streamer-layout .hand .tapped-wrapper:hover,
.virtual-board.streamer-layout .hand .tapped-wrapper.selected {
    transform: scale(1.25) !important;
    margin-left: 2vh;
    margin-right: 2vh;
}

.virtual-board.streamer-layout .board-sections {
    margin-top: 5vh;
}

.streamer-layout .central-section {
    flex-direction: column-reverse !important;
}

.streamer-layout .central-section .discard {
    margin-left: 2.5vh;
}

.streamer-layout .top-section {
    margin-bottom: 5vh;
}

.streamer-layout .central-section .virtual-board {
    margin-top: 5vh;
    --view-height: calc(100vh - var(--tumulte-height) - 12vh) !important;
    --bottom-section-height: -4vh !important;
}

.streamer-layout .virtual-board.spectating {
    overflow: hidden;
}

.streamer-layout .virtual-board.spectating .board-sections {
    margin-top: 0;
    position: absolute;
    bottom: 0.5vh;
    width: calc(100% - 1vh);
}

.streamer-layout .virtual-board.spectating .deck {
    display: none;
}

.streamer-layout .virtual-board.streamer-layout .board-sections>div:last-child {
    top: 0.5vh;
}

.streamer-layout .virtual-board.spectating .hand {
    top: -14vh !important;
    transform: rotate(180deg) scale(0.8);
}

.streamer-layout .virtual-board.spectating .board-sections {
    flex-direction: column-reverse !important;
}

.streamer-layout .virtual-board.spectating .board-sections>div:last-child {
    flex-direction: column-reverse !important;
}

.streamer-layout .power-counters .power-counter {
    transform: scale(1.4);
}

.streamer-layout .board.p-3D .deck .deck-background {
    opacity: 0 !important;
}

.streamer-layout .board.p-3D .deck .board-card {
    transform: translateY(0) !important;
}

.streamer-layout {
    --webcam-height: calc(100vh - var(--tumulte-height) - 9vh);
}

.streamer-layout .user-media-stream  {
    bottom: calc(var(--tumulte-height) + 6vh);
}