.home {}

.intro {
    background-image: url('./Assets/background.jpg');
    background-size: cover;
    background-position: top 0% right 50%;
    background-color: var(--secondary-background-color);
    height: 100vh;
    overflow: visible;
    /*box-shadow: inset 0 0 0 100vh rgba(201, 155, 99, 0.161);*/
}

.intro .intro-image {
    width: 120vh;
    max-width: 90vw;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: -25vh;
    z-index: 100;
    /*position: absolute;
    bottom: 5vh;
    left: calc((100vw - min(110vh, 90vw)) / 2);*/
}

[data-theme="dark"] .intro .intro-image {
    content: url('./Assets/Intro-dark.jpg');
}

.intro .h1-group {
    max-width: 90vw;
}

.intro .h1-group .bold {
    /*color: var(--main-color);*/
    color: white;
    /*text-shadow: #cfe3e6 0.1vh 0 7vh;*/
    /*text-shadow: #cfe5e848 0.1vh 0 3.5vh;*/
    /*text-shadow: 0 0 4vh #dbdadae1;*/
    filter: drop-shadow(0 0 0.5vh rgba(0, 0, 0, 0.472));
}

.intro .h1-group h1 {
    margin-bottom: 0;
    color: black;
}



.functionalities {
    padding-bottom: 5vh;
}

.functionalities h2 {
    margin-bottom: 10vh;
    font-weight: 700;
    font-size: 4vh;
    opacity: 0.9;
    text-transform: uppercase;
}

.functionalities p {
    margin-bottom: 0;
    margin-top: 1vh;
    white-space: pre-line;
}

.functionalities b {
    color: var(--main-color);
    font-weight: var(--font-weight-semi-bold);
}

.functionality {
    background-color: var(--white-background-color);
    max-width: 50vw;
    margin-left: auto;
    margin-right: auto;
    padding: 4vh;
    text-align: left;
    margin-top: 10vh;
    margin-bottom: 10vh;
    border-radius: var(--main-ui-border-radius);
    display: flex;
    flex-direction: row;
}

.functionality div {
    margin-left: -12vh;
}

.functionality.right div {
    margin-right: -12vh;
    margin-left: 0;
}

.functionality img {
    max-width: 30vw;
    position: relative;
    top: 0vh;
    left: -10vw;
    object-fit: cover;
    border-radius: var(--main-ui-border-radius);
}

.functionality.right img {
    max-width: 30vw;
    position: relative;
    left: 10vw;
}

.functionality h3 {
    color: black;
    background-color: var(--main-color);
    border-radius: var(--main-ui-border-radius);
    padding: 1.5vh;
    margin-left: -1vh;
    margin-right: -1vh;
    padding-left: 1.6vh;
    box-shadow: 0 0.5vh 0.75vh var(--shadow-color);
}

.functionalities .description {
    padding-top: 30vh;
    margin-bottom: 10vh;
    padding-bottom: 8vh;
    padding-left: 10vw;
    padding-right: 10vw;
}

@media (orientation: portrait) {
    .intro {
        height: 100vh;
    }

    .intro .intro-image {
        margin-bottom: 10vh;
    }

    .h1-group {
        margin-top: 6vh;
    }

    .intro .h1-group .bold {
        color: var(--main-color);
        /*text-shadow: #cfe3e6 0.1vh 0 7vh;*/
        /*text-shadow: #cfe5e848 0.1vh 0 3.5vh;*/
        text-shadow: 0 0 2vh transparent;
    }

    .navigation .buttons>a,
    .navigation .buttons>div {
        margin-top: 0.5vh;
    }

    .navigation .buttons>a {
        margin-left: 0 !important;
    }

    .functionalities .description {
        background-color: var(--secondary-background-color);
        padding-top: 8vh;
    }

    .functionalities h2 {
        margin-top: 5vh;
    }


    .functionality {
        max-width: 90vw;
        flex-direction: column;
        margin-top: 3vh;
        margin-bottom: 3vh;
    }

    .functionalities h2 {
        margin-bottom: 3vh;
    }

    .functionality img,
    .functionality.right img {
        top: 0;
        left: 0;
        max-width: calc(100% + 6vh);
        margin-left: -3vh;
        margin-right: -3vh;
        margin-top: -3vh;
    }

    .functionality div {
        margin-left: 0;
        margin-top: 3vh;
    }

    .functionality.right {
        flex-direction: column-reverse;
    }

    .functionality.right div {
        margin-right: 0;
        margin-left: 0;
    }
}

.patreons {
    background: linear-gradient(to right,
            #e6bd3500 10%,
            #e6bd35d7 80%,
            #e6bd35d1 100%), url('../VirtualDeck/Assets/background-2.jpeg');
    background-size: cover;
    height: 40vh;
    width: 100%;
    margin: 0;
}

.patreons div {
    padding: 0 10vh;
}