.help-section .carousel {
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 5vh;
}

.help-section .carousel .carousel-item {
    height: 50vh;
}

.help-section .carousel a {
    background-color: var(--main-color);
    margin-left: 1vh;
    margin-right: 1vh;
    width: 8vh
}

.help-section .carousel .carousel-item img {
    position: absolute;
    left: 10vh;
    width: calc(50% - 10vh);
    object-fit: cover;
    height: 43vh;
    border-top-left-radius: var(--main-ui-border-radius);
    border-bottom-left-radius: var(--main-ui-border-radius);
}

.help-section .carousel .carousel-item .carousel-caption {
    width: calc(50% - 10vh);
    right: 10vh;
    left: auto;
    height: 43vh;
    bottom: auto;
    top: 0;
    padding: 3vh;
    background-color: var(--white-background-color);
    border-top-right-radius: var(--main-ui-border-radius);
    border-bottom-right-radius: var(--main-ui-border-radius);
}

.help-section .carousel .carousel-item .carousel-caption h3 {
    margin-bottom: 3vh;
}

.help-section .carousel .carousel-item .carousel-caption p {
    text-align: start !important;
}

.carousel-indicators [data-bs-target] {
    background-color: var(--main-color) !important;
}

.carousel-indicators button {
    border-radius: 0.5vh !important;
    border: 0;
    box-shadow: none !important;
}

@media (orientation: portrait) {
    .carousel.slide {
        margin: 0 !important;
    }
    .active.carousel-item {
        flex-direction: column !important;
        height: 70vh !important;
    }
    
    .help-section .carousel .carousel-item img {
        height: 25vh;
        width: calc(100% - 10vh);
        left: 5vh;
        border-bottom-left-radius: 0;
        border-top-right-radius: var(--main-ui-border-radius);
    }

    .help-section .carousel .carousel-item .carousel-caption {
        height: 38vh;
        width: calc(100% - 10vh);
        top: 25vh;
        right: 5vh;
        border-bottom-left-radius: var(--main-ui-border-radius);
        border-top-right-radius: 0;
    }

    .carousel-control-next,
    .carousel-control-prev {
        margin-left: 0 !important;
        margin-right: 0 !important;
        width: 5vh !important;
        height: 8vh !important;
    }
}

.active.carousel-item {
    display: flex;
    flex-direction: row;
}