

.left-side.force .left-side-bar,
.left-side:hover .left-side-bar {
    left: 0;
}
/*
.left-bar {
    position: absolute;
    left: calc(var(--left-side-width) * -0.9);
    top: 0;
    height: 98vh;
    width: var(--left-side-width);
    transition: left 0.3s;
    backdrop-filter: blur(var(--main-blur));
    background-color: hsla(46, 85%, 95%, .7);
    border-radius: var(--main-ui-border-radius);
    margin: 1vh;
}
*/
.left-bar .show-hide-toggle {
    left: auto;
    right: calc(var(--side-bar-toggle-width) * -1);
    height: 100%;
}

.left-side-button {
    margin: auto;
    margin-top: 0.5vh;
    margin-bottom: 0.5vh;
    width: calc(var(--left-side-width) - 2vh);
    height: calc(var(--left-side-width) - 2vh);
    background-color: rgba(230, 224, 207, 0);
    padding: 1.5vh;
    position: relative;
}

.left-side-button img {
    filter: brightness(var(--img-brightness));
    width: calc(var(--left-side-width) - 3.7vh);
    height: calc(var(--left-side-width) - 3.7vh);
}

.left-side-button:hover img,
.left-side-button.selected img {
    filter: brightness(0.2);
}

.left-side-button p {
    position: absolute;
    right: 0;
    top: 0.5vh;
    background-color: var(--secondary-background-color);
    border-radius: 0.5vh;
    padding: 0.5vh 1vh;
    transition: opacity 0.15s;
    opacity: 0;
    pointer-events: none;
    transform: translateX(calc(100% + 3vh));
    min-width: 20vh;
}

.left-side-button:hover p {
    opacity: 1;
}

.left-side-button.unselectable {
    opacity: 0;
    pointer-events: none;
}

.left-bar .app-icon {
    width: 7vh !important;
    height: 7vh !important;
    margin-left: auto;
    margin-right: auto;
    padding: 1vh;
}

.left-bar-placeholder {
    max-width: calc(var(--left-side-width) + var(--side-bar-toggle-width));
    width: calc(var(--left-side-width) + var(--side-bar-toggle-width));
    transition: width 0.3s;
}

.hide-left-bar .left-bar-placeholder {
    width: var(--side-bar-toggle-width);
}

.hide-left-bar .left-bar {
    left: calc(var(--left-side-width) * -1)
}

.left-bar {
    max-width: var(--left-side-width);
    min-width: var(--left-side-width);
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    transition: left 0.3s;
    /*background: var(--secondary-background-color);*/
    z-index: 100000;
}

.left-bar .turn-counter {
    padding: 0.5vh;
}

.left-bar .turn-counter h2 {
    font-size: 1.3vh;
}

.left-bar .turn-counter p {
    background-color: var(--main-color);
    font-size: 1.3vh;
    margin: 0.5vh;
    border-radius: 0.5vh;
}

/* RIGHT MENU */


.right-side-placeholder {
    max-width: calc(var(--right-side-width) + var(--side-bar-toggle-width));
    width: calc(var(--right-side-width) + var(--side-bar-toggle-width));
    transition: width 0.3s;
}

.right-side {
    max-width: var(--right-side-width);
    min-width: var(--right-side-width);
    height: 100vh;
    position: absolute;
    top: 0;
    right: 0;
    transition: right 0.3s;
    z-index: 9000;
}

.show-hide-toggle {
    position: absolute;
    left: calc(var(--side-bar-toggle-width) * -1);
    top: 0vh;
    width: var(--side-bar-toggle-width);
    height: 100%;
    background-color: transparent;
    z-index: 5;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.show-hide-toggle:hover img {
    opacity: 1;
}

.show-hide-toggle img {
    width: 100%;
    padding: 0.3vh;
    opacity: 0.4;
}

.show-hide-toggle.virtual {
    top: 5vh;
}

.show-hide-toggle img {
    filter: brightness(var(--img-brightness));
}

.right-side .show-hide-toggle p {
    margin: 0;
}

.hide-right-side .right-side-placeholder {
    width: var(--side-bar-toggle-width);
}

.hide-right-side .right-side {
    right: calc(var(--right-side-width) * -1)
}