.card-recognition-column {
    --image-width: calc(var(--right-side-width) - 2vh);
    --image-height: calc(var(--image-width) * var(--card-size-ratio));
    height: 100%;
    max-height: calc(100% - 6vh);
}

.hide .result {
    display: none !important;
}

.card-recognition-column img {
    width: 100%;
    cursor: pointer;
    pointer-events: all;
}

.card-recognition-column .fullscreen-panel img {
    pointer-events: none;
}

.card-recognition-column {
    padding: 1vh;
    padding-bottom: 0;
}

.card-recognition-column img,
.card-recognition-column .no-image {
    width: var(--image-width);
    height: var(--image-height);
    /*z-index: 1000;*/
    border-radius: 1vh;
}

.card-recognition-column .no-image {
    border: 1px solid var(--text-color);
}

.card-recognition-column .focused-card-section>div a {
    transition: all 0.15s !important;
}

.card-recognition-column .focused-card-section>div:hover a {
    bottom: 8vh;
    opacity: 1;
}

.card-recognition-column .focused-card-section>div {
    overflow: hidden;
    height: var(--image-height);
    position: relative;
}

.card-recognition-column a,
.card-recognition-column p {
    margin: 0;
    position: relative;
}

.card-recognition-column p {
    bottom: 50%;
    text-align: center;
    transform: translateY(-50%);
}

.card-recognition-column a {
    bottom: -6vh;
    z-index: 1001;
    opacity: 0;
}

.card-recognition-column .focused-card-section>div .focused-card-image {
    transition: filter 0.15s;
}

.card-recognition-column .focused-card-section>div:hover .focused-card-image {
    filter: brightness(0.8);
}

.card-recognition-column .focused-card-section>div:hover .zoom-button-icon {
    opacity: 1;
}

.card-recognition-column .zoom-button-icon {
    position: absolute;
    top: calc(var(--image-height) / 2 - 3vh);
    left: calc(50% - 3vh);
    width: 6vh !important;
    height: 6vh !important;
    opacity: 0;
    transition: opacity 0.3s;
    pointer-events: none;
}

.focused-card-section {
    height: calc(var(--image-height));
}






.history {
    max-height: calc(100% - var(--image-height) - 7vh);
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 1vh;
    padding-bottom: 10vh;
}

.history .history-card {
    height: 7vh;
    min-height: 7vh;
    transition: all 0.3s;
}

.history .history-card:hover,
.history .history-card:last-child {
    height: calc(var(--image-height) * 0.8 * 1.15) !important;
    min-height: calc(var(--image-height) * 0.8 * 1.15) !important;
}

.history .history-card img {
    width: calc(var(--image-width) * 0.8);
    height: calc(var(--image-height) * 0.8);
    transform-origin: top;
    transition: all 0.3s;
    cursor: pointer;
}

.history .history-card:hover img {
    width: calc(var(--image-width) * 0.8 * 1.1);
    height: calc(var(--image-height) * 0.8 * 1.1);
}

.box-image {
    position: fixed;
    top: 4vh;
    left: 0;
    display: none;
}

.box-image img {
    width: 14vh;
    height: 14vh;
}

hr {
    margin-top: 1vh;
    margin-bottom: 1vh;
    color: var(--text-color)
}

.result {
    max-height: 100vh;
}

.right-side {
    /*background-color: var(--secondary-background-color);*/
}

.card-recognition-column {
    max-height: calc(100% - 9vh + 1rem);
}


.zoomed-image {
    transition: all 0.15s;
}

.zoomed-image.opacity-0 {
    pointer-events: none;
}

.zoomed-image img {
    height: 80vh !important;
    width: calc(80vh / var(--card-size-ratio)) !important;
    position: absolute;
    top: 10vh;
    right: calc(50vw - (80vh / var(--card-size-ratio)) / 2);
}



.card-search {
    --card-search-height: calc(6vh - 1rem);
    height: var(--card-search-height);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1vh;
}

.card-search p {
    font-size: var(--small-font-size);
}

.card-search .button {
    padding-top: 1.3vh !important;
    padding-bottom: 1.3vh !important;
    width: 6vh;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: var(--secondary-background-color);
}

.card-search input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background-color: var(--secondary-background-color);
    border: 0 !important;
    outline: 0 solid transparent !important;
    appearance: none;
    box-shadow: none !important;
    font-size: var(--small-font-size);
    color: var(--text-color) !important;
}

.card-search input::placeholder {
    color: var(--text-color);
    opacity: 0.7;
}

.card-search input:focus{
    background-color: var(--secondary-background-color);
    border: 0 solid black !important;
}

.card-search .button img {
    filter: brightness(var(--img-brightness));
    height: 100%;
    object-fit: contain;
}

.card-search .button:hover img {
    filter: brightness(0);
}

.card-search-results .set-selection {
    width: 100%;
}

.card-search-results .set-selection .button {
    margin-right: 1vh;
}

.card-search-results {
    overflow-y: hidden;
    position: absolute;
    height: 94vh;
    width: 100%;
    top: 6vh;
    background-color: var(--secondary-background-color);
}

.card-search-results .top-panel {
    margin: 1vh 1vh;
}

.card-search-results .results-section {
    overflow-y: auto;
    max-height: 100%;
    height: 100%;
    padding-left: 2vh;
    padding-right: 2vh;
}

.card-search-results .results-section img {
    border-radius: 1vh;
    margin-bottom: 2vh;
    cursor: pointer;
    transition: filter 0.15s;
    pointer-events: all;
}

.card-search-results img:hover {
    filter: brightness(0.7);
}

