.mode2v2 {
    --top-bar-height: 5vh !important;
    --tumulte-height: 13vh !important;
}

.mode2v2 .top-section {
    position: absolute !important;
    top: 50%;
    left: 0%;
    width: 100%;
    transform: translateY(-50%);
    z-index: 1000;
    backdrop-filter: blur(var(--main-blur));
    height: 101vh;
    transition: all 0.15s;
}

.top-section.minimized {
    backdrop-filter: blur(0);
    pointer-events: none;
    opacity: 0;
}

.top-section.minimized * {
    pointer-events: none !important;
}

.mode2v2 .top-section-background {
    cursor: pointer;
}

.mode2v2 .tumulte-section {
    --section-size: calc(var(--tumulte-card-width) * 4 + var(--center-tumulte-card-size));
    --center-tumulte-card-size: var(--tumulte-card-height);
    width: var(--center-tumulte-card-size);
    height: var(--center-tumulte-card-size);
    position: absolute;
    top: calc(50% - var(--center-tumulte-card-size) / 2);
    left: calc(50% - var(--center-tumulte-card-size) / 2);
    --tumulte-card-height: 14vh;
    --tumulte-card-width: calc(var(--tumulte-card-height) * var(--card-size-ratio));
    --tumulte-offset: calc(var(--tumulte-card-width) * 2 - var(--center-tumulte-card-size) + var(--tumulte-card-space-between));
    --token-size: 4.5vh;
    padding: 0;
}

.mode2v2 .center {
    position: absolute;
    top: 0;
    left: 0;
}

.mode2v2 .tumulte-card.center {
    width: var(--center-tumulte-card-size);
    height: var(--center-tumulte-card-size);
    margin: 0;
}

.mode2v2 .east,
.mode2v2 .west {
    position: absolute;
    top: 0;
    left: 0;
    width: var(--center-tumulte-card-size);
    height: var(--center-tumulte-card-size);
}

.mode2v2 .east {
    transform: rotate(180deg);
    left: calc(var(--tumulte-offset) + var(--center-tumulte-card-size) * 1);
}

.mode2v2 .west {
    left: calc(var(--tumulte-offset) * -1 + var(--center-tumulte-card-size) * -1);
}

.mode2v2 .north,
.mode2v2 .south {
    width: var(--center-tumulte-card-size);
    height: var(--center-tumulte-card-size);
    position: absolute;
    left: 0;
}

.mode2v2 .north {
    transform: rotate(-90deg);
    top: calc(var(--center-tumulte-card-size) * -1 - var(--tumulte-card-space-between));
}

.mode2v2 .south {
    transform: rotate(90deg);
    bottom: calc(var(--center-tumulte-card-size) * -1 - var(--tumulte-card-space-between));
}

.mode2v2 .section {
    width: calc(var(--tumulte-card-width) * 2);
    position: absolute;
}




/* ---------- POSITIONS ---------- */

.mode2v2 .player-tokens-area {
    margin: 0;
    height: 100%;
    width: 0;
    height: 0;
    left: 50%;
    top: 50%;
    --token-size-offset: calc(var(--token-size) / -2);
    --half-card-offset: calc((var(--tumulte-card-width) + 1vh) / 2 - 2vh);
    --token-extra-offset: 4vh;
    --positive-offset: 2.7vh;
    --negative-offset: -2.7vh;
    --center-tile-position-offset: 0.5vh;
    --start-end-offset: 2vh;
}

.mode2v2 .player-tokens-area .player-token {
    top: calc(var(--token-size-offset)) !important;
    left: calc(var(--token-size-offset));
    right: auto;
    bottom: auto;
    transition: left 0.3s, top 0.3s, right 0.3s, bottom 0.3s !important;
}

.mode2v2 .player-tokens-area .player-token.not-yours {
    pointer-events: none;
}

.mode2v2 .player-tokens-area .player-token.yours {
    pointer-events: all;
}


.mode2v2 .player-token-offset {
    left: 0 !important;
    transition: left 0.3s, top 0.3s, right 0.3s, bottom 0.3s, transform 0.3s !important;
    transform: translate(0, 0);
}

/* HERO AND COMPANION OFFSET */

.mode2v2 .player-token {
    --positive: 1vh;
    --negative: -1vh
}

.mode2v2 .player-token-offset {
    transform: translate(var(--x-translate), var(--y-translate));
}

.mode2v2 .companion {
    --x-translate: var(--negative);
    --y-translate: 0;
}

.mode2v2 .hero {
    --x-translate: var(--positive);
    --y-translate: 0;
}


.mode2v2 .position-7.companion .player-token-offset,
.mode2v2 .position-6.companion .player-token-offset,
.mode2v2 .position-5.companion .player-token-offset,
.mode2v2 .position-4.companion .player-token-offset {
    transform: translate(var(--y-translate), var(--x-translate));
}

.mode2v2 .position-7.hero .player-token-offset,
.mode2v2 .position-6.hero .player-token-offset,
.mode2v2 .position-5.hero .player-token-offset,
.mode2v2 .position-4.hero .player-token-offset {
    transform: translate(var(--y-translate), var(--x-translate));
}

.mode2v2 .player-1.position-0.hero .player-token-offset,
.mode2v2 .player-1.position-1.hero .player-token-offset,
.mode2v2 .player-1.position-2.hero .player-token-offset,
.mode2v2 .player-1.position-3.hero .player-token-offset {
    --x-translate: var(--negative);
    --y-translate: 0;
}

.mode2v2 .player-1.position-0.companion .player-token-offset,
.mode2v2 .player-1.position-1.companion .player-token-offset,
.mode2v2 .player-1.position-2.companion .player-token-offset,
.mode2v2 .player-1.position-3.companion .player-token-offset {
    --x-translate: var(--positive);
    --y-translate: 0;
}

.mode2v2 .player-3.position-7.hero .player-token-offset,
.mode2v2 .player-3.position-6.hero .player-token-offset,
.mode2v2 .player-3.position-5.hero .player-token-offset,
.mode2v2 .player-3.position-4.hero .player-token-offset {
    --x-translate: var(--negative);
    --y-translate: 0;
}

.mode2v2 .player-3.position-7.companion .player-token-offset,
.mode2v2 .player-3.position-6.companion .player-token-offset,
.mode2v2 .player-3.position-5.companion .player-token-offset,
.mode2v2 .player-3.position-4.companion .player-token-offset {
    --x-translate: var(--positive);
    --y-translate: 0;
}


.mode2v2 .player-4.companion .player-token-offset {
    --x-translate: var(--positive);
    --y-translate: 0;
}

.mode2v2 .player-4.hero .player-token-offset {
    --x-translate: var(--negative);
    --y-translate: 0;
}

/* PLAYER 1  */

.mode2v2 .player-1.position-0 .player-token-offset,
.mode2v2 .player-1.position-1 .player-token-offset,
.mode2v2 .player-1.position-2 .player-token-offset,
.mode2v2 .player-1.position-3 .player-token-offset {
    left: 0 !important;
    top: var(--negative-offset) !important;
}

.mode2v2 .player-1.position-4 .player-token-offset,
.mode2v2 .player-1.position-5 .player-token-offset,
.mode2v2 .player-1.position-6 .player-token-offset,
.mode2v2 .player-1.position-7 .player-token-offset {
    left: var(--negative-offset) !important;
    top: 0 !important;
}

/* PLAYER 2  */

.mode2v2 .player-2.position-0 .player-token-offset,
.mode2v2 .player-2.position-1 .player-token-offset,
.mode2v2 .player-2.position-2 .player-token-offset,
.mode2v2 .player-2.position-3 .player-token-offset {
    left: 0 !important;
    top: var(--negative-offset) !important;
}

.mode2v2 .player-2.position-4 .player-token-offset,
.mode2v2 .player-2.position-5 .player-token-offset,
.mode2v2 .player-2.position-6 .player-token-offset,
.mode2v2 .player-2.position-7 .player-token-offset {
    left: var(--positive-offset) !important;
    top: 0 !important;
}

/* PLAYER 3  */

.mode2v2 .player-3.position-0 .player-token-offset,
.mode2v2 .player-3.position-1 .player-token-offset,
.mode2v2 .player-3.position-2 .player-token-offset,
.mode2v2 .player-3.position-3 .player-token-offset {
    left: 0 !important;
    top: var(--positive-offset) !important;
}

.mode2v2 .player-3.position-4 .player-token-offset,
.mode2v2 .player-3.position-5 .player-token-offset,
.mode2v2 .player-3.position-6 .player-token-offset,
.mode2v2 .player-3.position-7 .player-token-offset {
    left: var(--positive-offset) !important;
    top: 0 !important;
}

/* PLAYER 4  */

.mode2v2 .player-4.position-0 .player-token-offset,
.mode2v2 .player-4.position-1 .player-token-offset,
.mode2v2 .player-4.position-2 .player-token-offset,
.mode2v2 .player-4.position-3 .player-token-offset {
    left: 0 !important;
    top: var(--positive-offset) !important;
}

.mode2v2 .player-4.position-4 .player-token-offset,
.mode2v2 .player-4.position-5 .player-token-offset,
.mode2v2 .player-4.position-6 .player-token-offset,
.mode2v2 .player-4.position-7 .player-token-offset {
    left: var(--negative-offset) !important;
    top: 0 !important;
}

/* ROTATIONS FOR BUTTONS */

.mode2v2 .player-token-offset {
    width: var(--token-size);
    height: var(--token-size);
}

.mode2v2 .player-token-offset>img {
    position: absolute;
    top: 0;
    left: 0;
}

.mode2v2 .token-move-buttons {
    width: var(--token-size);
    height: var(--token-size);
    position: absolute !important;
    top: 0;
    left: 0;
    transform-origin: center;
}

.mode2v2 .token-move-buttons .button {
    height: var(--token-size) !important;
    margin-left: 2.5vh;
    margin-right: 2.5vh;
}

.mode2v2 .position-7 .token-move-buttons,
.mode2v2 .position-6 .token-move-buttons,
.mode2v2 .position-5 .token-move-buttons,
.mode2v2 .position-4 .token-move-buttons {
    transform: rotate(90deg);
}

.mode2v2 .player-2.position-7 .token-move-buttons,
.mode2v2 .player-2.position-6 .token-move-buttons,
.mode2v2 .player-2.position-5 .token-move-buttons,
.mode2v2 .player-2.position-4 .token-move-buttons,
.mode2v2 .player-1.position-7 .token-move-buttons,
.mode2v2 .player-1.position-6 .token-move-buttons,
.mode2v2 .player-1.position-5 .token-move-buttons,
.mode2v2 .player-1.position-4 .token-move-buttons {
    transform: rotate(-90deg);
}

.mode2v2 .player-2.position-0 .token-move-buttons,
.mode2v2 .player-2.position-1 .token-move-buttons,
.mode2v2 .player-2.position-2 .token-move-buttons,
.mode2v2 .player-2.position-3 .token-move-buttons,
.mode2v2 .player-3.position-0 .token-move-buttons,
.mode2v2 .player-3.position-1 .token-move-buttons,
.mode2v2 .player-3.position-2 .token-move-buttons,
.mode2v2 .player-3.position-3 .token-move-buttons {
    transform: rotate(180deg);
}



.mode2v2 .token-move-buttons>div {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(calc(-50% - var(--token-size-offset)));
}

.mode2v2 .player-tokens-area .player-1.position-0,
.mode2v2 .player-tokens-area .player-4.position-0 {
    left: calc(var(--token-size-offset) - (var(--half-card-offset) * 3) - var(--token-extra-offset) - var(--start-end-offset));
}

.mode2v2 .player-tokens-area .player-1.position-1,
.mode2v2 .player-tokens-area .player-4.position-1 {
    left: calc(var(--token-size-offset) - (var(--half-card-offset) * 2) - var(--token-extra-offset));
}

.mode2v2 .player-tokens-area .player-1.position-2,
.mode2v2 .player-tokens-area .player-4.position-2 {
    left: calc(var(--token-size-offset) - (var(--half-card-offset) * 1) - var(--token-extra-offset));
}

.mode2v2 .player-tokens-area .player-1.position-3,
.mode2v2 .player-tokens-area .player-4.position-3 {
    left: calc(var(--token-size-offset) - var(--center-tile-position-offset) - var(--token-extra-offset)) !important;
}

.mode2v2 .player-tokens-area .player-1.position-4,
.mode2v2 .player-tokens-area .player-2.position-4 {
    top: calc(var(--token-size-offset) - var(--center-tile-position-offset) - var(--token-extra-offset)) !important;
}

.mode2v2 .player-tokens-area .player-1.position-5,
.mode2v2 .player-tokens-area .player-2.position-5 {
    top: calc(var(--token-size-offset) - (var(--half-card-offset) * 1) - var(--token-extra-offset)) !important;
}

.mode2v2 .player-tokens-area .player-1.position-6,
.mode2v2 .player-tokens-area .player-2.position-6 {
    top: calc(var(--token-size-offset) - (var(--half-card-offset) * 2) - var(--token-extra-offset)) !important;
}

.mode2v2 .player-tokens-area .player-1.position-7,
.mode2v2 .player-tokens-area .player-2.position-7 {
    top: calc(var(--token-size-offset) - (var(--half-card-offset) * 3) - var(--token-extra-offset) - var(--start-end-offset)) !important;
}




.mode2v2 .player-tokens-area .player-2.position-0,
.mode2v2 .player-tokens-area .player-3.position-0 {
    left: calc(var(--token-size-offset) + (var(--half-card-offset) * 3) + var(--token-extra-offset) + var(--start-end-offset));
}

.mode2v2 .player-tokens-area .player-2.position-1,
.mode2v2 .player-tokens-area .player-3.position-1 {
    left: calc(var(--token-size-offset) + (var(--half-card-offset) * 2) + var(--token-extra-offset));
}

.mode2v2 .player-tokens-area .player-2.position-2,
.mode2v2 .player-tokens-area .player-3.position-2 {
    left: calc(var(--token-size-offset) + (var(--half-card-offset) * 1) + var(--token-extra-offset));
}

.mode2v2 .player-tokens-area .player-2.position-3,
.mode2v2 .player-tokens-area .player-3.position-3 {
    left: calc(var(--token-size-offset) + var(--center-tile-position-offset) + var(--token-extra-offset)) !important;
}

.mode2v2 .player-tokens-area .player-3.position-4,
.mode2v2 .player-tokens-area .player-4.position-4 {
    top: calc(var(--token-size-offset) + var(--center-tile-position-offset) + var(--token-extra-offset)) !important;
}

.mode2v2 .player-tokens-area .player-3.position-5,
.mode2v2 .player-tokens-area .player-4.position-5 {
    top: calc(var(--token-size-offset) + (var(--half-card-offset) * 1) + var(--token-extra-offset)) !important;
}

.mode2v2 .player-tokens-area .player-3.position-6,
.mode2v2 .player-tokens-area .player-4.position-6 {
    top: calc(var(--token-size-offset) + (var(--half-card-offset) * 2) + var(--token-extra-offset)) !important;
}

.mode2v2 .player-tokens-area .player-3.position-7,
.mode2v2 .player-tokens-area .player-4.position-7 {
    top: calc(var(--token-size-offset) + (var(--half-card-offset) * 3) + var(--token-extra-offset) + var(--start-end-offset)) !important;
}