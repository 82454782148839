.virtual-board {
    width: 100%;
    height: 100%;
    position: relative;
    --view-height: 100vh;
    --right-side-width: 20vw;
    --card-height: calc((var(--view-height) - var(--bottom-section-height)) / 3 - 2vh - var(--border-width) * 2);
    --card-width: calc(var(--card-height) / var(--card-size-ratio));
    --section-height: calc(var(--card-height) + 2vh + var(--border-width));
    --section-width: calc(var(--card-width) + 0.75vh);
    --bottom-section-height: 15vh;
    --card-focused-section-width: var(--right-side-width);
    --card-focused-tokens-width: calc((var(--card-focused-section-width) - 2vh - 16vh) / 3);
    --border-width: 0.3vh;
    --pile-count-font-size: 5vh;
    outline-style: none;
}

.virtual-board.fullscreen {
    height: 100vh;
    overflow: hidden;
    width: 100vw;
}

.virtual-board.fullscreen .board .board-sections {
    /*transition: all 0.3s;*/
}

.virtual-board.fullscreen .board.p-3D {
    perspective: 4000px;
}

.virtual-board.fullscreen .board.p-3D .board-sections {
    transform: rotate3D(1, 0, 0, 15deg) translateY(-2vh);
    perspective-origin: bottom;
    padding-left: 2.5vh;
    padding-right: 2.5vh;
}

.virtual-board.fullscreen .board.p-3D .deck {
    overflow: visible;
}

.virtual-board .board .deck .deck-background {
    background-color: rgb(189, 182, 156);
    width: var(--card-width);
    height: var(--card-height);
    position: absolute;
    top: 0;
    z-index: -5;
    border-radius: calc(var(--card-height) * var(--card-border-radius-height-ratio));
    transition: all 0.3s;
    opacity: 1;
}

.virtual-board.fullscreen .board.p-3D .deck .board-card {
    overflow: visible;
}

.virtual-board.fullscreen .board.p-3D .extra {
    transform-style: flat;
}

.virtual-board .background {
    background-size: cover !important;
    background-position: center;
}

.virtual-board .right-side {
    min-width: var(--card-focused-section-width) !important;
    z-index: 3;
    position: relative;
    flex-shrink: 0;
    overflow-y: auto;
    overflow-x: hidden;
    height: 98vh;
    margin: 1vh;
    border-radius: var(--main-ui-border-radius);
    /*box-shadow: 0 0 1vh rgba(0, 0, 0, 0.102);*/
}

.virtual-board .right-side .main-content {
    overflow-y: scroll;
    height: 100%;
}

.virtual-board .right-side .bottom-section {
    box-shadow: 0 0 1vh var(--shadow-color);
}

.virtual-board .right-side .bottom-section * {
    color: black !important;
}

.virtual-board .right-side .content-section {
    border-radius: var(--main-ui-border-radius);
    overflow: hidden;
    padding: 1.5vh;
    margin-bottom: 2vh;
}

.virtual-board>div,
.virtual-board>div>.flex-row {
    height: 100%;
}

.virtual-board .board {
    width: 100%;
    min-width: 0;
    padding: 0.5vh;
    padding-bottom: var(--bottom-section-height);
    position: relative;
}

.virtual-board .waiting-selection .board-section {
    opacity: 0.5;
    pointer-events: none;
    border: var(--border-width) solid transparent;
}

.virtual-board .waiting-selection .board-section.deck {
    opacity: 1;
}

.virtual-board .board-section {
    border: var(--border-width) solid transparent;
    height: 100%;
    max-height: 100%;
    width: 100%;
    height: var(--section-height);
    margin: 0.5vh;
    padding: 1vh;
    border-radius: calc(var(--section-height) * var(--card-border-radius-height-ratio));
    z-index: 1;
    background-color: rgba(54, 54, 54, 0.084);
    position: relative;
    overflow-x: scroll;
    outline-style: none;
}

.virtual-board .board-section .section-title {
    position: absolute;
    top: -2vh;
    left: 0;
    text-transform: uppercase;
    font-size: 1.2vh;
}

.virtual-board .board-section .scrollable {
    display: flex;
    flex-direction: row;
    width: fit-content;
}



.virtual-board .board-section.selectable:hover {
    border: var(--border-width) solid var(--main-color);
    background-color: rgba(238, 220, 136, 0.413);
}

.virtual-board .board-section.selectable {
    background-color: rgba(255, 255, 255, 0.413);
    opacity: 1;
    pointer-events: all;
    border: var(--border-width) solid white;
}

.virtual-board .board-section.selectable .board-card {
    pointer-events: none;
}

.virtual-board .hero-expedition {}

.board-section.hero-expedition .scrollable {
    min-width: 100%;
}

.board-section.hero-expedition .scrollable .tapped-wrapper:first-child {
    margin-left: auto !important;
}

.virtual-board .hero .tapped {
    filter: grayscale(100);
}

.virtual-board .companion-expedition {}

.virtual-board .reserve {}

.virtual-board .landmarks {
    width: calc(var(--section-height) * 2.5)
}

.virtual-board .mana {
    position: relative;
}

.virtual-board .deck,
.virtual-board .deck .board-card {
    overflow: visible !important;
}

.deck-visual .deck-top {
    position: absolute;
    width: 100%;
    height: 100%;
}

.deck-visual .animated-part {
    position: absolute;
    top: 0vh;
    left: 0vh;
    animation: deck-draw 0.3s 0.15s forwards;
}

.deck-visual .disappearing-part {
    position: absolute;
    animation: deck-disappear 0.3s forwards;
}

@keyframes deck-disappear {
    0% {
        top: 0.1vh;
        left: 0vh;
        transform: rotate(3deg);
        opacity: 1;
    }

    40% {
        opacity: 1;
    }

    100% {
        top: 5vh;
        left: 3vh;
        opacity: 0;
        transform: rotate(15deg);
    }
}

@keyframes deck-draw {
    0% {
        top: 0vh;
        left: 0vh;
        transform: rotate(0deg);
        opacity: 1;
    }

    30% {
        opacity: 1;
    }

    100% {
        top: 0.1vh;
        left: 0vh;
        transform: rotate(3deg);
    }
}

.virtual-board .discard {
    z-index: 0;
}

.virtual-board .discard .old-discard {
    z-index: -1;
    position: absolute !important;
    top: 0;
    left: 0;
}
/*
.virtual-board.opponent .discard .old-discard {
    scale: 1 -1;
}
*/
.virtual-board .discard>div {
    height: 100%;
}

.virtual-board .pile-count {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}

.virtual-board .pile-count p {
    text-shadow: -0.5vh -0.5vh 0.5vh rgba(255, 255, 255, .1), 0.5vh 0.5vh 0.5vh rgba(0, 0, 0, .5);
}

.virtual-board .mana-count {
    top: 0.5vh;
    left: 0.5vh;
    width: fit-content;
    height: auto;
    pointer-events: all;
    z-index: 2;
}

.virtual-board .mana-count p {
    background-color: rgba(0, 0, 0, 0.332);
    backdrop-filter: blur(var(--main-blur));
    border-radius: var(--main-ui-border-radius);
    margin-bottom: 0.5vh !important;
    padding: 0 1vh;
    text-shadow: none;
}

.virtual-board .pile-count p {
    margin: 0;
    font-weight: var(--font-weight-semi-bold);
    color: white;
    font-size: var(--pile-count-font-size);
    pointer-events: none;
}

.virtual-board .board-section.single {
    width: var(--section-width);
    min-width: calc(var(--card-width) + var(--border-width) * 2);
    max-width: calc(var(--card-width) + var(--border-width) * 2);
    min-height: calc(var(--card-height) + var(--border-width) * 2);
    max-height: calc(var(--card-height) + var(--border-width) * 2);
    padding: 0 !important;
    margin-top: 1.5vh;
    border-radius: calc((var(--card-height) + var(--border-width) * 2) * var(--card-border-radius-height-ratio)) !important;
}

.virtual-board .right-side .board-card,
.virtual-board .discard-panel .board-card,
.virtual-board .board-section .board-card {
    width: var(--card-width);
    height: var(--card-height);
    /*border-radius: var(--main-ui-border-radius);*/
    border-radius: calc(var(--card-height) * var(--card-border-radius-height-ratio));
    overflow: hidden;
    position: relative;
    cursor: pointer;
    transition: all 0.2s;
}

.virtual-board .board-card .tokens {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding-bottom: 5vh;
}

.virtual-board .board-card .tokens>div {
    width: 20%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 0.25vh;
    margin-right: 0.25vh;
}

.virtual-board .board-card .tokens>div>div {
    margin-top: 0.2vh;
    margin-bottom: 0.2vh;
}

.virtual-board .board-card .tokens div.boost-token {
    background-color: rgba(0, 0, 0, 0.612);
    /* CAUSING ISSUE
    backdrop-filter: blur(1vh);*/
    border-radius: var(--main-ui-border-radius);
    margin-left: 0.25vh;
    margin-right: 0.25vh;
    padding: 0.5vh;
}

.virtual-board .board-card .tokens div img {
    object-fit: contain;
    height: fit-content;
}

.virtual-board .board-card .tokens div p {
    font-weight: var(--font-weight-semi-bold);
    font-size: 3vh;
    color: white;
    margin-bottom: 0 !important;
}

.virtual-board .scrollable .board-card {
    margin-right: 1vh;
    background-color: transparent !important;
}

.virtual-board .board .scrollable .tapped-wrapper.tapped .board-card {
    margin-right: calc(var(--card-height) - var(--card-width) + 1vh);
    border-right: 0 !important;
    transform: rotate(90deg) translateY(-14.2%);
}

.virtual-board .board .board-card>img {
    /*transition: all 0.2s;*/
}

.virtual-board .board .scrollable .tapped-wrapper.tapped {
    max-width: var(--card-height);
    min-width: 7vh;
}

.virtual-board .board .board-card:hover {
    filter: brightness(0.8);
}

.virtual-board .board .deck .board-card:hover {
    filter: brightness(1) !important;
}

.virtual-board .board-section .board-card img {
    width: 100%;
    height: 100%;
    opacity: 1;
}

.virtual-board .board-section .tapped-wrapper {
    opacity: 0;
    animation: appear 0.5s forwards;
}

.virtual-board.opponent .board-section .tapped-wrapper,
.virtual-board.spectator .board-section .tapped-wrapper {
    opacity: 0;
    animation: appear 0.5s 0.5s forwards;
}

.virtual-board .board-card .interaction {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.virtual-board .board-card:hover .interaction {
    opacity: 1;
}





.virtual-board .hand {
    width: fit-content;
    max-width: 90%;
    position: relative;
    top: -3vh;
    left: 50%;
    translate: -50% 0;
    display: flex;
    align-items: end;
    pointer-events: all;
    overflow: visible;
    z-index: 2;
    --hand-card-height: 25vh;
    --focus-top: -9vh;
}

.virtual-board .hand,
.virtual-board .deck {
    filter: drop-shadow(0 -2vh 1vh rgba(0, 0, 0, 0.133));
}

.virtual-board .hand .tapped-wrapper {
    transition: all 0.15s;
    cursor: pointer;
    position: relative;
    top: 0vh;
    border: 0 !important;
    max-width: calc(var(--hand-card-height) / var(--card-size-ratio));
    min-width: 1vh !important;
    width: 100%;
    height: var(--hand-card-height);
    pointer-events: all;
    display: inline-block;
    overflow: visible;
    flex: 1;
    animation: hand-appear-shadow 1s;
    border-radius: calc(var(--hand-card-height) * var(--card-border-radius-height-ratio));
    --hand-appear-translate-y: -31vh;
}

.virtual-board .hand .tapped-wrapper .card-image {
    animation: hand-appear 1s forwards;
}

.virtual-board .hand .tapped-wrapper:last-child {
    flex: 5;
    min-width: calc(25vh / var(--card-size-ratio));
}

.virtual-board .hand .tapped-wrapper img {
    object-fit: contain;
    width: calc(var(--hand-card-height) / var(--card-size-ratio));
    border-radius: calc(var(--hand-card-height) * var(--card-border-radius-height-ratio));
    height: var(--hand-card-height);
}

.virtual-board .hand .tapped-wrapper:hover,
.virtual-board .hand .tapped-wrapper.selected {
    scale: 1.5;
    transform-origin: bottom;
    margin-left: 4.2vh;
    margin-right: 4.2vh;
    top: var(--focus-top);
    flex: 5;
    min-width: calc(var(--hand-card-height) / var(--card-size-ratio));
}

.virtual-board .hand .tapped-wrapper .board-card:hover {
    filter: brightness(1);
}

.virtual-board .hand .tapped-wrapper.selected {
    top: 0vh;
}

.virtual-board .tapped-wrapper.is-dragging {
    opacity: 0 !important;
    pointer-events: none !important;
}

.virtual-board .card-focused {
    height: 100vh;
    width: 100%;
    backdrop-filter: blur(var(--main-blur));
    padding: 1vh;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 100;
}

.virtual-board .card-focused .background {
    z-index: -100;
    cursor: pointer;
}

.virtual-board .card-focused .board-card {
    width: calc(var(--card-focused-section-width) - 2vh);
    min-height: calc((var(--card-focused-section-width) - 2vh) * var(--card-size-ratio));
    border-radius: var(--main-ui-border-radius);
    overflow: hidden;
}

.virtual-board .card-focused .board-card img {
    object-fit: contain;
    width: 100%;
    /*height: fit-content;*/
}

.virtual-board .card-focused .buttons-lign button {
    min-width: calc(50% - 0.5rem);
}

.virtual-board .card-focused .quick-tokens {
    background-color: var(--secondary-background-color);
    padding: 0.5vh;
    margin-top: 0.5vh;
    border-radius: var(--main-ui-border-radius);
}

.virtual-board .card-focused .quick-tokens>div>* {
    width: var(--card-focused-tokens-width);
}

.virtual-board .card-focused .quick-tokens>.token-column {
    padding: 0.6vh;
}

.virtual-board .card-focused .quick-tokens>.token-column>div {
    margin: 0.25vh;
}

.virtual-board .card-focused .quick-tokens .divers img {
    filter: brightness(var(--img-brightness));
    transform: scale(1.1);
}

.virtual-board .card-focused .quick-tokens img {
    object-fit: contain;
    height: calc(var(--card-focused-tokens-width) - 2.7vh);
    width: calc(var(--card-focused-tokens-width) - 2.7vh);
}

.virtual-board .card-focused .quick-tokens button {
    margin-top: 1vh;
    margin-bottom: 1vh;
}

.virtual-board .card-focused .quick-token-button {
    border-radius: 100%;
    border: 0.5vh solid transparent;
    width: fit-content
}

.virtual-board .card-focused .quick-token-button.selected {
    border: 0.5vh solid var(--main-color);
}




.virtual-board .card-focused::-webkit-scrollbar,
.virtual-board .board-section::-webkit-scrollbar,
.virtual-board .tokens-row::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.virtual-board .card-focused,
.virtual-board .board-section,
.virtual-board .tokens-row {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}


.virtual-board .discard-panel {
    padding-top: 5vh;
    padding-bottom: 10vh;
}

.virtual-board .discard-panel .close-button {
    position: fixed;
    top: 0;
    right: 0;
    width: 6vh;
    margin: 2vh;
    cursor: pointer;
    filter: brightness(0);
}

.virtual-board .discard-panel .close-button:hover {
    opacity: 0.7;
}

.virtual-board .discard-panel .flex-row>div {
    margin: 1vh;
}

.virtual-board .discard-panel .board-card {
    height: fit-content;
    width: fit-content;
    margin: 1vh;
}

.virtual-board .discard-panel .board-card img {
    height: calc(var(--view-height) / 2);
    width: calc((var(--view-height) / 2) / var(--card-size-ratio));
}

.virtual-board .discard-panel .flex-row {
    overflow-x: auto;
    padding-left: 5vh;
    padding-right: 5vh;
    padding-bottom: 3vh;
}

.virtual-board .accordion {
    margin-top: 2vh;
}

.virtual-board .accordion h2 {
    font-size: var(--main-font-size) !important;
    margin: 0;
}

.virtual-board .new-turn-button {
    cursor: pointer;
}



.virtual-board .accordion p {
    text-align: left;
}

.virtual-board .accordion-body {
    padding: 1vh;
}




.background-image-picker {
    width: 100%;
    height: 20vh;
    background-size: cover !important;
    margin: 1vh;
    border-radius: var(--main-ui-border-radius);
    cursor: pointer;
}

.background-image-picker:hover {
    opacity: 0.8;
}

.background-choice-1 {
    background-image: url('./Assets/background-1.jpeg') !important;
}

.background-choice-2 {
    background-image: url('./Assets/background-2.jpeg') !important;
}

.background-choice-3 {
    background-image: url('./Assets/background-3.jpeg') !important;
}

.background-choice-4 {
    background-image: url('./Assets/background-4.jpeg') !important;
}

.background-choice-5 {
    background-image: url('./Assets/background-5.jpeg') !important;
}

.background-choice-6 {
    background-image: url('./Assets/background-6.jpeg') !important;
}

.background-choice-7 {
    background-image: url('./Assets/background-7.jpeg') !important;
}

.background-choice-8 {
    background-image: url('./Assets/background-8.jpeg') !important;
}

.background-choice-9 {
    background-image: url('./Assets/background-9.jpeg') !important;
}

.background-choice-10 {
    background-image: url('./Assets/background-10.jpeg') !important;
}


@keyframes appear {
    0% {
        opacity: 0;
        transform: translateY(4vh);
    }

    50% {
        transform: translateY(0);
    }

    100% {
        opacity: 1;
    }
}

@keyframes hand-appear {
    0% {
        opacity: 0;
        transform: translateX(20%) translateY(0vh) scale(1);
        transform-origin: right;
        pointer-events: none;
    }

    5% {
        opacity: 0;
        transform: translateX(20%) translateY(var(--hand-appear-translate-y)) scale(1);
        transform-origin: right;
        pointer-events: none;
    }

    20%,
    80% {
        opacity: 1;
        transform: translateX(20%) translateY(var(--hand-appear-translate-y)) scale(1.4);
        transform-origin: right;
        pointer-events: none;
    }
/*
    70% {
        transform: translateX(0) scale(1);
        transform-origin: right;
        pointer-events: none;
    }
*/
    100% {
        opacity: 1;
        pointer-events: all;
    }
}

@keyframes hand-appear-shadow {
    0%, 100% {
        box-shadow: none;
        pointer-events: none;
    }
}


.item-exit-active {
    opacity: 0;
    transition: opacity 700ms ease-out;
}

.virtual-board .deck:hover .extra {
    opacity: 1;
}

.virtual-board .extra {
    position: absolute;
    top: 0.25vh;
    right: 0.25vh;
    opacity: 0;
    z-index: 5000;
}

.virtual-board .extra .extra-toggler {
    background-color: var(--secondary-background-color);
    border-radius: 50%;
    width: 4vh;
    height: 4vh;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    transition: opacity 0.2s;
}

.virtual-board .extra .extra-toggler p {
    font-size: 3.5vh;
    transform: translateY(-0.2vh);
}

.virtual-board .extra:hover .extra-toggler {
    opacity: 0;
}

.virtual-board .extra:hover .extra-content {
    opacity: 1;
    pointer-events: all;
}

.virtual-board .extra .extra-content {
    opacity: 0;
    background-color: var(--secondary-background-color);
    width: 18vh;
    height: 24vh;
    position: absolute;
    top: calc((24vh - 4vh) * -1);
    left: 0;
    padding: 1vh;
    padding-bottom: 0;
    transition: opacity 0.2s;
    border-radius: var(--main-ui-border-radius);
    pointer-events: none;
}

.virtual-board .tmp-button {
    position: relative;
}

.virtual-board .tmp-button p {
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 0.5rem);
    margin: 0;
    border: 1px solid var(--text-color);
    height: calc(100% - 0.5rem);
    margin: 0.25rem;
    border-radius: 0.5vh;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: var(--font-weight-semi-bold);
}

.virtual-board .tmp-button:hover p {
    opacity: 0;
    pointer-events: none;
}

.virtual-board .tmp-button div {
    opacity: 0;
}

.virtual-board .tmp-button:hover div {
    opacity: 1;
}


/* OVERLAPING BOARD SECTIONS */

.virtual-board .scrollable.overlaping {
    width: auto;
    display: flex;
}

.virtual-board .board-section .scrollable.overlaping .tapped-wrapper {
    flex: 1 1;
    display: inline-block;
    min-width: 7vh;
    max-width: calc(var(--card-width) + 1vh);
    border-radius: 0;
    margin: 0;
    overflow: visible;
    border-right: 1vh solid transparent;
}

.virtual-board .board-section .scrollable.overlaping .tapped-wrapper:last-child {
    flex: 5;
    min-width: var(--card-width);
    border-right: 0;
    max-width: var(--card-width) !important;
    margin-right: 0;
}

.virtual-board .board-section .scrollable.overlaping .tapped-wrapper:hover {
    min-width: var(--card-width);
    max-width: calc(var(--card-width) + 1vh);
}

.virtual-board .board-section .scrollable.overlaping .tapped-wrapper.tapped:last-child,
.virtual-board .board-section .scrollable.overlaping .tapped-wrapper.tapped:hover {
    min-width: var(--card-height);
    max-width: var(--card-height);
}



.virtual-board .board-section .scrollable.overlaping .tapped-wrapper.mana-hidden:hover {
    min-width: 7vh;
    max-width: calc(var(--card-width) + 1vh) !important;
}

.virtual-board .board-section .scrollable.overlaping .tapped-wrapper.mana-hidden:last-child {
    min-width: var(--card-width);
    max-width: var(--card-width) !important;
}

.virtual-board .board-section .scrollable.overlaping .tapped-wrapper.tapped.mana-hidden:last-child {
    max-width: var(--card-height) !important;
}

.virtual-board .board-section .scrollable.overlaping .board-card>img {
    border-radius: calc(var(--card-height) * var(--card-border-radius-height-ratio));
    width: var(--card-width);
    height: var(--card-height);
}

.virtual-board .tapped-wrapper {
    transition: min-width 0.15s, max-width 0.15s;
    position: relative;
}


.virtual-board .board .evening-section {
    position: absolute;
    top: -100%;
    left: 0;
    width: calc(100% - 2vh);
    z-index: 2;
    padding: 1vh;
    backdrop-filter: blur(var(--main-blur));
    opacity: 0;
    pointer-events: none;
    transition: all 0.5s;
    margin: 1vh;
    border-radius: var(--main-ui-border-radius);
    overflow: hidden;
}

.virtual-board .board.evening .evening-section {
    opacity: 1;
    pointer-events: all;
    top: 0;
}


.virtual-board .quick-action {
    width: 3vh;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.392);
    backdrop-filter: blur(var(--main-blur));
    position: absolute;
    right: 0;
    top: 0;
    z-index: 900;
    display: none !important;
}


.fullscreen-panel .lang-select-wrapper {
    width: 12vh;
    height: 4.5vh;
    font-size: var(--main-font-size);
}

.virtual-board .lang-select-wrapper {
    width: 12vh;
    height: 4.5vh;
    font-size: var(--main-font-size);
}

.virtual-board .focus-card-buttons {
    position: absolute;
    top: 0.5vh;
    right: 0.5vh;
    width: fit-content;
}

.virtual-board .focus-card-button:hover {
    background-color: rgba(0, 0, 0, 0.708);
}

.small-round-button,
.virtual-board .focus-card-button {
    width: 4vh;
    height: 4vh;
    opacity: 0;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s;
    background-color: rgba(0, 0, 0, 0.768);
    border-radius: 50%;
    /*backdrop-filter: blur(var(--main-blur));*/
    padding: 1vh;
    margin-left: 0.8vh;
}

.virtual-board .hand .focus-card-button {
    transform-origin: top right;
    transform: scale(0.75);
}

.small-round-button p,
.virtual-board .focus-card-button p {
    color: white;
}


/*
.virtual-board .hand .focus-card-button {
    border-top-left-radius: calc(var(--hand-card-height) * var(--card-border-radius-height-ratio));
    border-top-right-radius: calc(var(--hand-card-height) * var(--card-border-radius-height-ratio));
}
*/

.virtual-board .waiting-selection .hand * {
    pointer-events: none !important;
}

.virtual-board .board-card .focus-card-button.exhaust,
.virtual-board .board-card .focus-card-button.support {
    display: none;
}

.virtual-board .hero-expedition .board-card .focus-card-button.exhaust,
.virtual-board .companion-expedition .board-card .focus-card-button.exhaust,
.virtual-board .hero .board-card .focus-card-button.exhaust,
.virtual-board .landmarks .board-card .focus-card-button.exhaust,
.virtual-board .reserve .board-card .focus-card-button.support {
    display: flex;
}

.mobile-mode.your-section .focus-card-button,
.mobile-mode.fullscreen .focus-card-button {
    opacity: 1 !important;
    pointer-events: all !important;
}

.virtual-board .board-card:hover .focus-card-button {
    opacity: 1;
    pointer-events: all;
}

.virtual-board .drag-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.virtual-board.opponent .board-card {
    pointer-events: none;
}

.virtual-board.opponent .discard .board-card {
    pointer-events: all;
}

.virtual-board .reserve .missing-mana .board-card,
.virtual-board .hand .missing-mana .board-card .card-image {
    outline: 0.3vh solid transparent !important;
    /*box-shadow: 0 0 0 transparent; */
}

.virtual-board.opponent .hand .board-card .card-image {
    outline: 0.3vh solid transparent !important;
    /*box-shadow: 0 0 0 transparent; */
}

.virtual-board .reserve .board-card,
.virtual-board .hand .board-card .card-image {
    outline: 0.25vh solid var(--main-color);
    /*box-shadow: 0 0 1.5vh var(--main-color); */
}

.virtual-board .card-power-and-cost {
    display: none !important;
    position: absolute;
    top: 0;
    left: 0;
}
/*
.virtual-board .zoomed-card.focused-card {
    opacity: 0.7;
}
*/

.virtual-board.opponent .tokens-panel {
    display: none !important;
}

.virtual-board .tokens-button {
    position: absolute;
    top: 0vh;
    right: 0vh;
    opacity: 1;
    pointer-events: all !important;
    --radius: 0.7vh;
    border-top-left-radius: var(--radius);
    border-top-right-radius: var(--radius);
    border-bottom-right-radius: var(--radius);
}

.virtual-board .tokens-panel {
    --row-margin: 0.5vh;
    pointer-events: none;
    position: absolute;
    top: var(--row-margin);
    left: var(--row-margin);
    width: calc(100% - var(--row-margin) * 2);
    /*backdrop-filter: blur(0);*/
    z-index: 1;
    border-radius: var(--main-ui-border-radius);
}

.virtual-board .tokens-panel:hover {
    pointer-events: all !important;   
    backdrop-filter: blur(var(--main-blur)); 
}

.virtual-board .tokens-panel .blurred-background {
    opacity: 0;
    border-radius: var(--main-ui-border-radius);
}

.virtual-board .tokens-panel:hover .blurred-background {
    opacity: 0.8;
}

.virtual-board .tokens-panel:hover .tokens-button {
    display: none;
}

.virtual-board .tokens-panel:hover .tokens-row {
    display: flex !important;
}

.virtual-board .tokens-panel .tokens-row {
    height: 6vh;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 0 1vh !important;
    max-width: 100%;
    display: none !important;
}

.virtual-board .tokens-panel .tokens-row .button {
    margin-left: 0.5vh;
    margin-right: 0.5vh;
}

.virtual-board .quick-token-selection-info {
    backdrop-filter: blur(var(--main-blur));
    position: absolute;
    top: 1vh;
    left: calc(50% - 10vh);
    width: 25vh;
    height: 4vh;
    z-index: 1;
    border-radius: var(--main-ui-border-radius);
    overflow: hidden;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0s 0.15s;
}

.virtual-board .quick-token-selection-info.hide {
    opacity: 0;
    transition: 0s;
}

.virtual-board .background-mask {
    position: absolute;
    top: 0; 
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.virtual-board .background-mask .night {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: background-color 0.5s;
    z-index: 1;
    pointer-events: none;
}

.virtual-board:has(.board.evening) .background-mask .night {
    background-color: rgba(6, 6, 44, 0.464);
}

.virtual-board .time-travel-warning {
    background-color: rgba(0, 0, 0, 0.753);
    padding: 0.5vh 2.8vh;
    color: white;
    z-index: 1;
    backdrop-filter: blur(var(--main-blur));
    border-bottom-left-radius: var(--main-ui-border-radius);
    border-bottom-right-radius: var(--main-ui-border-radius);
    cursor: pointer;
}

.virtual-board .time-travel-warning:hover {
    opacity: 0.7;
}





.virtual-board .card-played-anim {
    width: var(--card-width);
    height: var(--card-height) !important;
    border-radius: calc(var(--card-height) * var(--card-border-radius-height-ratio));
    transition: all 0.5s;
    animation: card-played-anim 0.6s forwards;
    z-index: 2;
    overflow: hidden;
}

.virtual-board .card-played-anim.hidden img {
    content: url('./Assets/card-back.png') !important;
}

.virtual-board.opponent .card-played-anim {
    scale: -1 -1;
}

.virtual-board .card-played-anim.ini-pos {
    transition: none;
}

@keyframes card-played-anim {
    0% {
        opacity: 0.5;
        scale: 1;
    }

    20%, 50% {
        opacity: 1;
        scale: 1.2;
    }

    100% {
        opacity: 0;
        scale: 1;
    }
}