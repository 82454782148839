textarea {
    height: 55vh;
    border: 0;
    margin-bottom: 2vh;
    padding: 1vh;
    border-radius: var(--main-ui-border-radius);
    resize: none; 
    font-size: var(--main-font-size);
    background-color: var(--white-background-color);
    color: var(--text-color);
}

.decklist-cards {
    margin-left: auto;
    margin-right: auto;
    height: 60vh;
    overflow-y: scroll;
    width: 50%;
    padding: 2vh;
    margin-top: 1vh;
}

.decklist-cards .decklist-card.hero {
    height: 30vh;
}

.decklist-cards .decklist-card {
    height: 8vh; 
}

.decklist-cards .decklist-card img {
    width: 100%;
    border-radius: 2vh;
}

p.decklist-loading {
    white-space: pre-wrap;
    background-color: var(--white-background-color);
    width: 50%;
    border-radius: var(--main-ui-border-radius);
}