.draft-panel {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    --draft-card-width: calc(var(--draft-card-height) / var(--card-size-ratio));
    --draft-card-height: 34vh;
    background-image: url('../Home/Assets/background.jpg');
    background-size: cover;
}

.draft-panel .navigation {
    pointer-events: none;
    box-shadow: none !important;
    backdrop-filter: none;
}

.draft-panel .navigation .background,
.draft-panel .navigation .navbar-collapse {
    display: none !important;
}

.draft-panel .participants {
    position: absolute;
    top: 0;
    right: 21vh;
    z-index: 10000;
}

.draft-panel .nav-items {
    position: absolute;
    top: 0;
    right: -20.5vh;
    z-index: 10000;
}

.draft-panel .participants>p {
    margin: 1vh 1vh;
}

.draft-panel .participants>p.ready {
    outline: 0.25vh solid var(--main-color);
    box-shadow: 0 0 1.5vh var(--main-color);
}

.draft-panel .background-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(var(--main-blur));
}

.draft-panel.drafting .background-wrapper .blurred-background {
    z-index: auto;
    opacity: 0.15;
}

[data-theme="dark"] .draft-panel.drafting .background-wrapper .blurred-background {
    opacity: 0.35;
}

.draft-panel .waiting-messgage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.draft-panel .cards-picked-show {
    position: fixed;
    left: 50%;
    top: calc(100% - 8vh);
    transform: translateX(-50%);
    transition: top 0.15s;
    z-index: 1000000;
}

.draft-panel .cards-picked-show.top {
    top: 2vh;
}

.pack-wrapper {
    width: 40vh;
    height: 70vh;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: pack-appear 1s
        /*, pack-shadow 4s infinite*/
    ;
    filter: drop-shadow(0 1vh 5vh rgba(0, 0, 0, 0.3));
}

.pack-wrapper>div {
    transition: transform 0.5s;
}

.pack-wrapper>div:hover {
    transition: none;
}

.pack-opening .pack-wrapper {}

.pack-wrapper .pack-cut {
    position: absolute;
    top: -52vh;
    width: 2vh;
    height: 50vh;
    background: linear-gradient(180deg, rgb(255, 255, 255) 10%, rgba(251, 251, 251, 0));
    border-radius: 50%;
    left: calc(50% - 1vh);
}

.draft-panel.pack-opening .pack-wrapper .pack-cut {
    animation: pack-cut 1.2s forwards;
}

@keyframes pack-cut {
    0% {
        top: calc(100% + 52vh);
        display: flex;
    }

    99% {
        top: -52vh;
        display: none;
    }
}

@keyframes pack-appear {
    0% {
        translate: 0% 100%;
    }

    100% {
        translate: 0% 0%;
    }
}

/*
.pack-wrapper h2 {
    z-index: 1000;
    font-size: 8vh;
    text-shadow: 0 0 1.5vh black;
    color: white !important;
}
*/
/*
.pack-wrapper:hover {
    scale: 1.05;
}
*/
.pack-part {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.pack-left {
    -webkit-mask-image: linear-gradient(-90deg, transparent 49%, rgba(0, 0, 0, 1) 50%);
}

.pack-right {
    -webkit-mask-image: linear-gradient(90deg, transparent 49%, rgba(0, 0, 0, 1) 50%);
}


.booster-pack {
    width: 100%;
    height: 100%;
}

.pack-opening .booster-remainings {
    opacity: 0;
}

.booster-remainings {
    position: fixed;
    top: 50%;
    right: 10vh;
    height: 25vh;
    width: calc(50% - 35vh);
    translate: 0% -50%;
    opacity: 1;
    transition: opacity ease-in-out 1s;
    pointer-events: none;
}

.booster-remaining {
    position: relative;
    top: 0;
    object-fit: contain;
    margin: 0 2vh;
    filter: drop-shadow(0 1vh 5vh rgba(0, 0, 0, 0.3));
}

.draft-section {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}







.cards {
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    width: calc(var(--draft-card-width) * 6);
    height: calc(var(--draft-card-height) * 2);
    overflow: visible;
    /*filter: drop-shadow(0 0 0.5vh rgba(0, 0, 0, 0.18));PERF*/
}

.draft-panel.leaving .cards {
    transition: transform 1s;
    transform: translateX(120vw);
}

.draft-panel.switching .cards {
    transition: none;
    transform: translateX(-120vw);
}

.draft-panel.leaving .cards *,
.draft-panel.switching .cards *,
.draft-panel.entering .cards *,
.draft-panel.entered .cards * {
    transition: none;
    animation-duration: 0s !important;
    animation-delay: 0s !important;
}

.draft-panel.leaving .cards *,
.draft-panel.switching .cards *,
.draft-panel.entering .cards * {
    pointer-events: none !important;
}

.draft-panel.entered .cards * {
    pointer-events: all !important;
}

.draft-panel.entering .cards {
    transition: transform 1s;
    transform: translateX(0%);
}

.cards .card-wrapper {
    left: 50%;
    top: 50%;
    position: absolute;
    width: var(--draft-card-width);
    height: var(--draft-card-height);
    padding: 3%;
    scale: 1.3;
    translate: -50% -50%;
    --anim-duration: 1.5s;
    /*transition: transform 0.1s;*/
    cursor: pointer;
    pointer-events: none;
}

.cards .card-wrapper.picked {
    opacity: 0;
    pointer-events: none !important;
}

.cards .card-wrapper .card {
    width: calc(100% - 2vh);
    height: calc(100% - 2vh);
    border-radius: var(--main-ui-border-radius);
    transform: rotateY(90deg);
    position: absolute;
    top: 1vh;
    left: 1vh;
    border: 0 !important;
    pointer-events: none;
}

.cards .card-wrapper .card-back {
    position: absolute;
    top: 1vh;
    left: 1vh;
    width: calc(100% - 2vh) !important;
    height: calc(100% - 2vh) !important;
    border-radius: var(--main-ui-border-radius);
    opacity: 1;
    transform: rotateY(180deg);
    pointer-events: none;
}



.cards .card-wrapper.rare .card {
    outline: 0.25vh solid var(--main-color);
    box-shadow: 0 0 1.5vh var(--main-color);
}

.cards .card-wrapper:hover {
    z-index: 100;
}

.cards-picked .card-picked:has(.zoom-hover:hover) {
    z-index: 100;
}


.cards .card-wrapper:has(.zoom-hover:hover) .card,
.cards-picked .card-picked:has(.zoom-hover:hover) img {
    scale: 1.5;
}

.cards .zoom-hover,
.cards-picked .zoom-hover {
    position: absolute;
    left: 0;
    top: 0;
    width: var(--draft-card-width);
    height: var(--draft-card-height);
}

.cards-picked .zoom-hover {
    left: 1vh;
    top: 1vh;
}

/* ANIMATIONS */








.draft-panel.pack-opening {}

@keyframes cards-expend {
    0% {
        left: 50%;
        top: 50%;
        scale: 1.5;
    }

    60% {
        left: var(--target-left);
        top: var(--target-top);
        translate: 0% 0%;
        scale: 1;
    }

    99.9% {
        pointer-events: none;
    }

    100% {
        left: var(--target-left);
        top: var(--target-top);
        translate: 0% 0%;
        scale: 1;
        pointer-events: all;
    }
}

.draft-panel .cards {
    opacity: 0;
}

.draft-panel.pack-opening .cards {
    opacity: 1;
}

.draft-panel.pack-opening .cards .card-wrapper:nth-child(1) {
    --target-left: 0;
    --target-top: 0;
    animation: cards-expend var(--anim-duration) 2s forwards;
}

.draft-panel.pack-opening .cards .card-wrapper:nth-child(2) {
    --target-left: calc(var(--draft-card-width) * 1);
    --target-top: 0;
    animation: cards-expend var(--anim-duration) 2.05s forwards;
}

.draft-panel.pack-opening .cards .card-wrapper:nth-child(3) {
    --target-left: calc(var(--draft-card-width) * 2);
    --target-top: 0;
    animation: cards-expend var(--anim-duration) 2.1s forwards;
}

.draft-panel.pack-opening .cards .card-wrapper:nth-child(4) {
    --target-left: calc(var(--draft-card-width) * 3);
    --target-top: 0;
    animation: cards-expend var(--anim-duration) 2.15s forwards;
}

.draft-panel.pack-opening .cards .card-wrapper:nth-child(5) {
    --target-left: calc(var(--draft-card-width) * 4);
    --target-top: 0;
    animation: cards-expend var(--anim-duration) 2.20s forwards;
}

.draft-panel.pack-opening .cards .card-wrapper:nth-child(6) {
    --target-left: calc(var(--draft-card-width) * 5);
    --target-top: 0;
    animation: cards-expend var(--anim-duration) 2.25s forwards;
}

.draft-panel.pack-opening .cards .card-wrapper:nth-child(7) {
    --target-left: 0;
    --target-top: calc(var(--draft-card-height) * 1);
    animation: cards-expend var(--anim-duration) 2.30s forwards;
}

.draft-panel.pack-opening .cards .card-wrapper:nth-child(8) {
    --target-left: calc(var(--draft-card-width) * 1);
    --target-top: calc(var(--draft-card-height) * 1);
    animation: cards-expend var(--anim-duration) 2.35s forwards;
}

.draft-panel.pack-opening .cards .card-wrapper:nth-child(9) {
    --target-left: calc(var(--draft-card-width) * 2);
    --target-top: calc(var(--draft-card-height) * 1);
    animation: cards-expend var(--anim-duration) 2.4s forwards;
}

.draft-panel.pack-opening .cards .card-wrapper:nth-child(10) {
    --target-left: calc(var(--draft-card-width) * 3);
    --target-top: calc(var(--draft-card-height) * 1);
    animation: cards-expend var(--anim-duration) 2.45s forwards;
}

.draft-panel.pack-opening .cards .card-wrapper:nth-child(11) {
    --target-left: calc(var(--draft-card-width) * 4);
    --target-top: calc(var(--draft-card-height) * 1);
    animation: cards-expend var(--anim-duration) 2.5s forwards;
}

.draft-panel.pack-opening .cards .card-wrapper:nth-child(12) {
    --target-left: calc(var(--draft-card-width) * 5);
    --target-top: calc(var(--draft-card-height) * 1);
    animation: cards-expend var(--anim-duration) 2.55s forwards;
}

@keyframes flip-front {
    0% {
        transform: rotateY(90deg);
    }

    100% {
        transform: rotateY(0deg);
    }
}

.draft-panel.pack-opening .cards .card-wrapper .card {
    animation: flip-front 0.5s 4s forwards;
}


@keyframes flip-back {
    0% {
        transform: rotateY(180deg);
    }

    100% {
        transform: rotateY(90deg);
    }
}

.draft-panel.pack-opening .cards .card-wrapper .card-back {
    animation: flip-back 0.5s 3.5s forwards;
}

/*.draft-panel.pack-opening .draft-section {*/
.draft-panel.pack-opening .pack-wrapper>div {
    animation: open-pack-section-move 4s;
    transition-duration: 300ms;
    transition-property: transform, box-shadow;
    transition-timing-function: ease-out;
}

@keyframes open-pack-section-move {
    0% {
        scale: 1;
    }

    10%,
    40% {
        scale: 0.8;
    }

    100% {
        scale: 1;
    }
}

.draft-panel .pack-wrapper .shine {
    background-image: radial-gradient(circle at 50% -20%, #ffffff22, #0000000f);
}

.draft-panel .pack-wrapper .glow {
    width: 93%;
    height: 98%;
    border: none;
    border-radius: 5%;
}

.draft-panel.pack-opening .glow {
    --glow-color: rgba(255, 221, 67, 0.9);
    animation: pack-open-glow 1.1s forwards;
}

@keyframes pack-open-glow {
    0% {
        box-shadow: 0px 0px 0vh 0vh var(--glow-color);
        opacity: 1;
    }

    20% {
        box-shadow: 0px 0px 4vh 1vh var(--glow-color);
        opacity: 1;
    }

    40% {
        box-shadow: 0px 0px 4vh 2vh var(--glow-color);
        opacity: 1;
    }

    100% {
        box-shadow: 0px 0px 0vh 0vh var(--glow-color);
        opacity: 0;
    }
}

.draft-panel.pack-opening .pack-left {
    animation: pack-open-left 2s 1s forwards;
}

.draft-panel.pack-opening .pack-right {
    animation: pack-open-right 2s 1s forwards;
}

@keyframes pack-open-right {
    0% {
        opacity: 1;
    }

    20% {
        translate: 10% 0%;
        rotate: 5deg;
        opacity: 1;
    }

    40% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        translate: 130% 100%;
        rotate: 20deg;
    }
}

@keyframes pack-open-left {
    0% {
        opacity: 1;
    }

    20% {
        translate: -10% 0%;
        rotate: -5deg;
        opacity: 1;
    }

    40% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        translate: -130% 100%;
        rotate: -20deg;
    }
}

.draft-panel.pack-opening .pack-wrapper {
    pointer-events: none;
    animation: shake 0.3s 3 ease-in-out;
}

@keyframes shake {
    0% {
        rotate: 0deg;
    }

    25% {
        rotate: 2.5deg;
    }

    50% {
        rotate: 0deg;
    }

    75% {
        rotate: -2.5deg;
    }

    100% {
        rotate: 0deg;
    }
}
























.draft-panel .cards-picked-panel {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100000;
    backdrop-filter: blur(var(--main-blur));
    transition: top 0.15s;
}

.draft-panel .cards-picked-panel .blurred-background {
    opacity: 1;
}

.draft-panel .cards-picked-panel.hidden {
    top: 100%;
}

.draft-panel .cards-picked-wrapper {
    width: 100%;
    height: 92vh;
    margin-top: 8vh;
    overflow-y: scroll;
}

.draft-panel .cards-picked {
    width: calc((var(--draft-card-width) + 2vh) * 6);
    margin-left: auto;
    margin-right: auto;
    margin-top: 7vh;
}

.draft-panel .card-picked {
    padding: 1vh;
    transform-origin: center;
    height: fit-content;
    position: relative;
}

.draft-panel .card-picked>div {
    height: var(--draft-card-height);
}

.draft-panel .card-picked img {
    width: var(--draft-card-width);
    height: var(--draft-card-height);
    border-radius: calc(var(--draft-card-height) * var(--card-border-radius-height-ratio));
}















.draft-panel .deck-build .card-picked:hover {
    scale: none;
    z-index: 0 !important;
}

.draft-panel .deck-build .card-picked>div {
    cursor: pointer;
    transition: all 0.15s;
    border-radius: calc(var(--draft-card-height)* var(--card-border-radius-height-ratio));
}

.draft-panel .deck-build .card-picked>div:hover>img {
    filter: brightness(0.8)
}

.draft-panel .deck-build .card-picked {
    animation: card-picked-appear 0.3s forwards;
    --card-picked-appear-start: -100%;
}

.draft-panel .deck-build .deck-rubbish .card-picked {
    --card-picked-appear-start: 100% !important;
}


.draft-panel .draft-card-count {
    font-size: 4vh;
    text-shadow: 0 0vh 1vh rgba(0, 0, 0, 0.923), 0 0vh 1.5vh rgba(0, 0, 0, 0.923), 0 0vh 2vh rgba(0, 0, 0, 0.923);
    ;
    color: white;
    top: 5vh !important;
    right: 3vh !important;
}

.draft-panel .deck-list .draft-card-count {
    top: 4vh !important;
    right: 2vh !important;
}


@keyframes card-picked-appear {
    0% {
        opacity: 0;
        transform: translateY(var(--card-picked-appear-start));
    }

    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}

.draft-panel .deck-build {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 0vh;
    width: 100%;
    --draft-card-width: calc(var(--draft-card-height) / var(--card-size-ratio));
    --draft-card-height: 25vh;
}

.draft-panel .deck-rubbish {
    height: 100%;
    min-height: calc(10vh);
    position: relative;
}

.draft-panel .deck-rubbish .side-scroll {
    width: 100%;
    padding-left: 2vh;
    padding-top: 5vh;
    overflow-x: hidden;
    overflow-y: hidden;
    display: grid !important;
    grid-auto-flow: column;
    grid-template-rows: repeat(auto-fit, minmax(250px, 1fr));
    justify-content: start;
}

.draft-panel .deck-list {
    height: 100%;
    overflow: auto;
    position: relative;
}

.draft-panel .resize-wrapper {
    min-height: 38vh;
    max-height: calc(90vh);
}

[data-theme="dark"] .draft-panel .deck-list .blurred-background {
    /*background-color: rgba(0, 0, 0, 0.507);*/
}

.draft-panel .deck-list .blurred-background {
    background-color: var(--background-color);
    opacity: 1;
    z-index: 0;
}

.draft-panel .deck-list .col {
    padding: 0 1vh;
}

.draft-panel .deck-list .card-picked {
    height: 5.5vh;
    padding: 0;
    position: relative;
}

.draft-panel .deck-list .card-picked.stacked {
    height: 9vh;
}

.draft-panel .deck-list .card-picked:last-child {
    height: fit-content;
}

.draft-panel .deck-list .cards-list {
    margin-left: auto;
    margin-right: auto;
    width: calc((var(--draft-card-width) + 2vh) * 7);
    --draft-card-width: calc(var(--draft-card-height) / var(--card-size-ratio));
    --draft-card-height: 30vh;
    margin-bottom: 5vh;
    margin-top: 9vh;
    position: relative;
}

.draft-panel .waiting-selection .deck-rubbish:hover .side-scroll,
.draft-panel .waiting-selection .deck-list:hover .cards-list-wrapper {
    opacity: 0.2;
}

.draft-panel .waiting-selection .deck-rubbish:hover .drop-info-message,
.draft-panel .waiting-selection .deck-list:hover .drop-info-message {
    opacity: 1;
}

.draft-panel .drop-info-message {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
}

.draft-panel .deck-info {
    height: 6.5vh;
}

.draft-panel .deck-info p,
.draft-panel .deck-info .button {
    margin: 0 1vh;
}

.draft-panel .deck-info .banners img {
    width: 4vh;
    height: 7.2vh;
    margin-top: -3.2vh;
    margin-left: 0.5vh;
    margin-right: 0.5vh;
    object-fit: cover;
    overflow: visible;
    pointer-events: all;
    transition: all 0.15s;
    cursor: pointer;
    opacity: 1;
}

.draft-panel .deck-info .banners img:hover {
    margin-top: -2vh;
}

.draft-panel .deck-info .banners img.absent {
    filter: grayscale(100%);
    opacity: 0.7;
}


.row-cols-7>* {
    flex: 0 0 auto;
    width: 14.2%;
}

.draft-panel .deck-info .banners img.absent.is-filter {
    filter: grayscale(100%) drop-shadow(0.4vh 0 0 var(--main-color)) drop-shadow(0 0.4vh 0 var(--main-color)) drop-shadow(-0.4vh 0 0 var(--main-color)) drop-shadow(0 -0.4vh 0 var(--main-color));
}

.draft-panel .deck-info .banners img.is-filter {
    opacity: 1;
    filter: drop-shadow(0.4vh 0 0 var(--main-color)) drop-shadow(0 0.4vh 0 var(--main-color)) drop-shadow(-0.4vh 0 0 var(--main-color)) drop-shadow(0 -0.4vh 0 var(--main-color));
}

.draft-panel .types-filters>* {
    font-weight: var(--regular-font-weight);
}



.draft-panel .faction-filter .deck-rubbish .card-picked,
.draft-panel .faction-filter .deck-list .card-picked,
.draft-panel .type-filter .deck-rubbish .card-picked,
.draft-panel .type-filter .deck-list .card-picked {
    position: fixed;
    top: -100vh;
    pointer-events: none;
}

.draft-panel .type-filter.faction-filter .deck-rubbish .card-picked,
.draft-panel .type-filter.faction-filter .deck-list .card-picked {
    position: fixed !important;
    top: -100vh !important;
    pointer-events: none !important;
}

.draft-panel .faction-filter.AXIOM .card-picked.AXIOM,
.draft-panel .faction-filter.LYRA .card-picked.LYRA,
.draft-panel .faction-filter.MUNA .card-picked.MUNA,
.draft-panel .faction-filter.YZMIR .card-picked.YZMIR,
.draft-panel .faction-filter.ORDIS .card-picked.ORDIS,
.draft-panel .faction-filter.BRAVOS .card-picked.BRAVOS,
.draft-panel .type-filter.HERO .card-picked.HERO,
.draft-panel .type-filter.SPELL .card-picked.SPELL,
.draft-panel .type-filter.CHARACTER .card-picked.CHARACTER,
.draft-panel .type-filter.PERMANENT .card-picked.PERMANENT {
    position: relative;
    top: 0;
    pointer-events: all;
}

.draft-panel .faction-filter.AXIOM.HERO .card-picked.AXIOM.HERO,
.draft-panel .faction-filter.LYRA.HERO .card-picked.LYRA.HERO,
.draft-panel .faction-filter.MUNA.HERO .card-picked.MUNA.HERO,
.draft-panel .faction-filter.YZMIR.HERO .card-picked.YZMIR.HERO,
.draft-panel .faction-filter.ORDIS.HERO .card-picked.ORDIS.HERO,
.draft-panel .faction-filter.BRAVOS.HERO .card-picked.BRAVOS.HERO,
.draft-panel .faction-filter.AXIOM.SPELL .card-picked.AXIOM.SPELL,
.draft-panel .faction-filter.LYRA.SPELL .card-picked.LYRA.SPELL,
.draft-panel .faction-filter.MUNA.SPELL .card-picked.MUNA.SPELL,
.draft-panel .faction-filter.YZMIR.SPELL .card-picked.YZMIR.SPELL,
.draft-panel .faction-filter.ORDIS.SPELL .card-picked.ORDIS.SPELL,
.draft-panel .faction-filter.BRAVOS.SPELL .card-picked.BRAVOS.SPELL,
.draft-panel .faction-filter.AXIOM.CHARACTER .card-picked.AXIOM.CHARACTER,
.draft-panel .faction-filter.LYRA.CHARACTER .card-picked.LYRA.CHARACTER,
.draft-panel .faction-filter.MUNA.CHARACTER .card-picked.MUNA.CHARACTER,
.draft-panel .faction-filter.YZMIR.CHARACTER .card-picked.YZMIR.CHARACTER,
.draft-panel .faction-filter.ORDIS.CHARACTER .card-picked.ORDIS.CHARACTER,
.draft-panel .faction-filter.BRAVOS.CHARACTER .card-picked.BRAVOS.CHARACTER,
.draft-panel .faction-filter.AXIOM.PERMANENT .card-picked.AXIOM.PERMANENT,
.draft-panel .faction-filter.LYRA.PERMANENT .card-picked.LYRA.PERMANENT,
.draft-panel .faction-filter.MUNA.PERMANENT .card-picked.MUNA.PERMANENT,
.draft-panel .faction-filter.YZMIR.PERMANENT .card-picked.YZMIR.PERMANENT,
.draft-panel .faction-filter.ORDIS.PERMANENT .card-picked.ORDIS.PERMANENT,
.draft-panel .faction-filter.BRAVOS.PERMANENT .card-picked.BRAVOS.PERMANENT {
    position: relative !important;
    top: 0 !important;
    pointer-events: all !important;
}




.draft-panel .zoomed-card img {
    width: 35vh !important;
    height: calc(35vh * var(--card-size-ratio));
}

.draft-panel .fullscreen-panel {
    z-index: 9999999999999;
}

.draft-panel .deck-list-export {
    background-color: var(--grey-background-color);
    border-radius: var(--main-ui-border-radius);
    padding: 2vh;
}

.draft-panel .deck-list-export>p {
    user-select: all;
    white-space: pre-line;
    font-size: 1.2vh;
}


.draft-panel .lang-select-wrapper {
    width: 14vh;
    height: 5vh;
    margin: 0.5vh 1vh;
}