.toggle-theme-wrapper {
    position: relative;
}

.toggle-theme-wrapper img:last-child {
    opacity: 1;
}

.toggle-theme-wrapper img:first-child {
    display: none;
}

.toggle-theme-wrapper:hover img {
    filter: brightness(0.2) !important;
}

.navigation .toggle-theme-wrapper img {
    margin-right: 0 !important;
    margin-left: 0 !important;
    height: 2.3vh !important;
    width: 2.3vh !important;
}

[data-theme="dark"] .toggle-theme-wrapper img:first-child {
    display: block;
}

[data-theme="dark"] .toggle-theme-wrapper img:last-child {
    display: none;
}