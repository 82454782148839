.about {
    padding-top: 2vh;
}

.about button {
    padding: 2vh !important;
    height: fit-content !important;
    background-color: var(--main-color) !important;
}

.about button h2 {
    margin: 0;
    font-size: var(--main-font-size);
}

.about button:hover {
    background-color: var(--main-color) !important;
}

.about p {
    text-align: start;
}