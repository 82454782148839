.play-home .box {
    background-color: var(--white-background-color) !important;
    border-radius: var(--main-ui-border-radius);
    overflow: hidden;
    cursor: pointer;
    height: auto !important;
    display: 'flex';
    flex: 1;
    padding: 0 !important;
    align-items: start;
    justify-content: start;
    position: relative;
    margin: 0 !important;
}

.play-home .box:hover {
    transform: scale(0.93);
}

.play-home .box>img {
    width: 100%;
    height: 45vh;
    object-fit: cover;
    object-position: top;
}

.play-home .box.horizontal>img {
    width: 30vw;
}

.play-home .box>div {
    padding: 3vh;
    text-align: start;
}

.play-home .box>div p {
    margin: 0;
}

.play-home .play-indicator {
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: var(--main-color-transparent);
    position: absolute;
    transition: all 0.2s;
    -webkit-backdrop-filter: blur(1vh);
    backdrop-filter: blur(1vh);
}

.play-home .box:hover .play-indicator {
    opacity: 1;
}

.play-home .play-indicator img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 7vh;
    height: 7vh;
}