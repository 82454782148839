.error-messages {
    position: absolute;
    top: 12vh;
    left: calc(50% - 40vw);
    width: 0;
}

.error-messages>div {
    width: 80vw;
}

.error-messages>div>div {
    background-color: var(--secondary-background-color);
    border-radius: var(--main-ui-border-radius);
    backdrop-filter: blur(2vh);
    padding: 1.5vh;
    margin-bottom: 2vh;
    border: 1px solid rgba(0, 0, 0, 0.143);
}

.error-messages>div>div p {
    color: var(--text-color);
    margin: 0;
}

.error-messages>div>div img {
    width: 3vh;
    height: 3vh;
    filter: brightness(var(--img-brightness));
    pointer-events: all;
}

.error-messages>div>div img:hover {
    opacity: 0.7;
    cursor: pointer;
}

.player-game-section .error-messages {
    left: 10%;
    width: 80%;
    top: 1%;
    z-index: 2;
}

.player-game-section .error-messages>div {
    width: 100%;
}