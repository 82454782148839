.central-section {
    --tumulte-card-height: calc(var(--tumulte-height) - 1vh);
    --tumulte-card-width: calc(var(--tumulte-card-height) * var(--card-size-ratio));
    --tumulte-card-space-between: 0.5vh;
    --token-size: calc(var(--tumulte-height) / 3.3);
    --space-token-to-top-bottom-border: 2.6vh;
    --space-token-to-side-start-border: 4.3vh;
}

.tumulte-section {
    padding-top: 0.5vh;
    padding-left: var(--tumulte-card-space-between);
    padding-right: var(--tumulte-card-space-between);
    position: relative;
    height: var(--tumulte-height);
}

.tumulte-card {
    margin-left: var(--tumulte-card-space-between);
    margin-right: var(--tumulte-card-space-between);
    display: flex;
    height: var(--tumulte-card-height);
    width: var(--tumulte-card-width);
    overflow: visible;
    position: relative;
}

.tumulte-card .tumulte-card-image {
    object-fit: cover;
    border-radius: 1vh;
    height: var(--tumulte-card-height);
    width: 100%;
    z-index: 1;
}

.tumulte-card .tumulte-card-image.animated {
    transition: transform 0.8s, opacity 0.5s 0.15s;
}

.tumulte-card .tumulte-card-image.visible {
    opacity: 1;
    transform: rotateY(0deg);
}

.tumulte-card .tumulte-card-image.hide {
    opacity: 0;
    transform: rotateY(180deg);
}

.tumulte-card .tumulte-card-image.card-back {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
}

.tumulte-start {
    width: 100%;
    height: var(--tumulte-card-height);
    max-width: var(--tumulte-card-width);
    display: flex;
    margin-left: var(--tumulte-card-space-between);
    margin-right: var(--tumulte-card-space-between);
}

.tumulte-start .tumulte-card-image {
    height: var(--tumulte-card-height);
    width: 100%;
    object-fit: cover;
    border-radius: 1vh;
}

.tumulte-section .tumulte-position-tokens {
    height: 100%;
    width: 100%;
    position: relative;
}



.player-token {
    position: absolute;
    bottom: var(--space-token-to-top-bottom-border);
    width: var(--token-size);
    height: var(--token-size);
    transition: left 0.3s;
}

.player-token.opponent {
    top: var(--space-token-to-top-bottom-border) !important;
}

.tumulte-section .tumulte-position-token {
    border-radius: 50%;
    width: var(--token-size);
    transition: transform 0.2s;
}

.player-token-offset .token-move-buttons {
    pointer-events: none;
    z-index: 1000;
}

.player-token-offset:hover {
    z-index: 100;
}

.player .player-token-offset:hover .tumulte-position-token {
    transform: scale(1.15);
}

.player-token-offset:hover .token-move-buttons {
    opacity: 1;
    pointer-events: all;
}

.tumulte-card .player-token-offset {
    position: absolute;
}

.token-move-buttons {
    opacity: 0;
}

.token-move-buttons>div {
    position: absolute;
    top: 0;
    left: -8.5vh;
}

.token-move-buttons .button {
    width: var(--token-size);
    border: 0;
    height: var(--token-size);
    margin-left: 3vh;
    margin-right: 3vh;
}

.token-move-buttons .button img {
    filter: brightness(0);
    width: 2.5vh;
    height: 2.5vh;
}

.glow {
    border: 2px solid #238ed5;
    box-shadow: 0 0 5px #238ed58b; /* Reduced Spread */
}

.opponent .glow,
.opponent.glow {
    border: 2px solid #d52338;
    box-shadow: 0 0 5px #d5232f8b; /* Reduced Spread */
}

.player-tokens-area {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 1vh);
    z-index: 2;
    margin-top: 0.5vh;
    --centering-offset: var(--tumulte-card-width) / 4;
    --half-card: calc(var(--tumulte-card-width) / 2);
    --center-position: calc(50% - (var(--token-size) / 2) - var(--half-card) + var(--centering-offset));
}

.player-tokens-area .position-0 {
    left: calc(var(--center-position) + var(--half-card) - (var(--tumulte-card-width) + 1vh) * 2);
}

.player-tokens-area .position-1 {
    left: calc(var(--center-position) - (var(--tumulte-card-width) + 1vh) * 1);
}

.player-tokens-area .position-2 {
    left: calc(var(--center-position) + var(--half-card) - (var(--tumulte-card-width) + 1vh) * 1);
}

.player-tokens-area .position-3 {
    left: calc(var(--center-position));
}

.player-tokens-area .position-4 {
    left: calc(var(--center-position) + var(--half-card));
}

.player-tokens-area .position-5 {
    left: calc(var(--center-position) + (var(--tumulte-card-width) + 1vh) * 1);
}

.player-tokens-area .position-6 {
    left: calc(var(--center-position) + var(--half-card) + (var(--tumulte-card-width) + 1vh) * 1);
}

.player-tokens-area .position-7 {
    left: calc(var(--center-position) + (var(--tumulte-card-width) + 1vh) * 2);
}

.hero .player-token-offset {
    position: absolute;
    left: -1vh;
}

.companion .player-token-offset {
    position: absolute;
    right: -1vh;
}