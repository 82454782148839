.navigation {
    backdrop-filter: blur(var(--main-blur));
    /*background-color: hsla(46, 85%, 95%, .7);*/
    /*background-color: hsla(46, 85%, 95%, 0.916);*/
    margin-bottom: 3vh;
    padding: 0.5vh 4.5vh;
    z-index: 9000;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.navigation .background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--background-color);
    opacity: 0.8;
    z-index: 0;
}

.navigation h3 {
    display: inline-flex;
    align-items: center;
    margin: 0;
    font-size: var(--navigation-font-size);
}

.navigation .app-icon {
    width: 4vh;
    height: 4vh;
    padding: 0;
    margin-right: 1vh;
}

.navigation .buttons {
    /*background-color: var(--secondary-background-color) !important;
    border-radius: 3vh;
    padding-left: 1vh;
    padding-right: 1vh;
    padding-top: 0.5vh;
    padding-bottom: 0.5vh;*/
}

.navigation>a, 
.navigation #main-navbar-nav {
    z-index: 1;
}

.navigation>button {
    z-index: 1;
}

.navigation>a,
.navigation>a:hover {
    background-color: transparent !important;
}

.navigation .buttons a {
    height: auto !important;
}

.navigation .buttons a {
    background-color: transparent;
    font-size: var(--navigation-font-size);
    padding-right: 2vh !important;
    padding-left: 2vh !important;
}

.navigation .buttons a,
.navigation .button.toggle-theme-wrapper,
.navigation .lang-select-wrapper {
    margin-left: 1vh !important;
    margin-right: 1vh !important;
}

.navigation .buttons p {
    margin: 0;
    font-weight: 550;
    font-size: var(--navigation-font-size);
}

.navigation .buttons a.selected {
    background-color: var(--main-color)
}

.navigation .buttons a.selected p,
.navigation .buttons a:hover p {
    color: black;
}

.navigation .buttons a.selected img,
.navigation .buttons a:hover img {
    filter: brightness(0.2);
}

.navigation .buttons img {
    filter: brightness(var(--img-brightness));
    width: calc(var(--navigation-font-size) * 1.3);
    height: calc(var(--navigation-font-size) * 1.3);
    margin-right: 1vh;
}

.navigation.shadowed {
    /*box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;*/
}

.navigation .button {
    height: auto !important;
    margin: 0 !important;
}

[data-theme="dark"] .navbar-toggler {
    border: var(--bs-border-width) solid white;
}

[data-theme="dark"] .navbar-toggler-icon {
    filter: invert(100%);
}

.lang-select-wrapper {
    padding: 0 1vh;
    border-radius: 0.5vh;
    background-color: transparent;
    cursor: pointer;
    display: flex;
}

.lang-select {
    border: 0;
    background-color: transparent;
    font-weight: 550;
    font-size: var(--navigation-font-size);
    color: var(--text-color);
    cursor: pointer;
    outline: 0 !important;
}

.lang-select-wrapper:hover {
    background-color: var(--main-color);
    opacity: 0.8;
}

.lang-select-wrapper:hover .lang-select {
    color: black;
}