.page-with-navigation-background {
    background-image: url('../Home/Assets/background.jpg');
    background-size: cover;
    clip-path: polygon(100% 0, 100% 85%, 50% 100%, 0 85%, 0 0);
    height: 20vh;
}

.page-with-navigation-content {
    padding-left: 3vw;
    padding-right: 3vw;
    padding-top: 0;
    width: 100%;
}

.page-with-navigation {
    width: 100%;
    overflow-x: hidden;
}

.page-with-navigation .description {
    margin-left: calc(-3vw + 15%);
    margin-right: calc(-3vw + 15%);
    margin-bottom: 5vh;
    margin-top: -5vh;
    padding: 5vh;
    padding-bottom: 0;
    height: 20vh;
    background-color: transparent;
    width: calc(70% + 6vw);
    box-shadow: 
    -5vh 0 var(--background-color),         /*Left masking shadow*/
    5vh 0 var(--background-color),
    0 1vh 4vh var(--shadow-color);
    display: flex;
    align-items: center;
    justify-content: center;
}

.page-with-navigation .description p {
    margin: 0;
}