.opponent-connection .box-container>p {
    text-align: start;
}

.opponent-connection .box-container>h2 {
    margin-bottom: 4vh;
}

.game.relay .power-counter,
.game.relay .tumulte-section, 
.game.relay .first-player-token {
    pointer-events: none;
}

.game.relay .player-only {
    display: none !important;
}

.game.relay .first-player-token {
    top: calc(var(--tumulte-height) - 4vh);
    right: 50%;
    transform: translateX(50%);
    width: 8.5vh;
    z-index: 1000;
}

.game.relay .first-player-token .token {
    right: -5vh;
    left: auto;
    bottom: 4vh !important;
    transition: right 0.1s linear;
}

.game.relay .first-player-token.opponent .token {
    right: 6vh;
    bottom: 4vh !important;
}

.game.relay .first-player-token .token .glow {
    border: 0;
    box-shadow: none;
}

.game.relay .first-player-token p {
    display: none;
}

.game.relay .virtual-board.spectating {
    --view-height: calc(88vh - var(--tumulte-height) - 7vh) !important;
    transform: translateY(5vh);
}

.game.relay .react-player video {
    transform: translateY(5vh);
    height: calc(88vh - var(--tumulte-height) - 7vh) !important;
}

.game.relay .virtual-board.spectating .hand {
    overflow: visible;
    --hand-card-height: 20vh;
    top: -50vh !important
}

.game.relay .virtual-board.spectating .hand * {
    pointer-events: all !important;
}

.game.relay .virtual-board.spectating .hand .board-card:hover,
.game.relay .virtual-board.spectating .hand .board-card.selected {
    margin-left: 3.5vh !important;
    margin-right: 3.5vh !important;
    top: 12vh !important;
}