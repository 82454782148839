.player-game-sections {
    --game-sections-height: calc(100vh - var(--tumulte-height));
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: var(--player-sections-gap);
    grid-auto-rows: minmax(100px, auto);
    padding: var(--player-sections-gap);
    height: var(--game-sections-height);
    --top-bar-height: 4.5vh;
    width: 100%;
    --player-game-section-border-radius: 0;
}

.webcam-virtual-section.changing-focus * {
    transition: none !important;
}

.webcam-virtual-section.changing-focus .mana * {
    animation-duration: 0s !important;
    transition: none !important;
}

.player-game-sections.focused {
    grid-template-columns: repeat(1, minmax(0, 1fr));
}

.mode2v2 .player-game-sections.focused {
    grid-template-columns: repeat(1, minmax(0, 1fr));
}

.mode2v2 .player-game-sections {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-auto-rows: minmax(0, 1fr);
    --game-sections-height: 100vh;
    padding: 0.25vh;
}

.mode2v2 .player-game-sections.focused {
    --game-sections-height: calc(100vh);
}

.player-game-sections-mini {
    padding-left: 1vh;
    width: 17vh;
}

.player-game-sections-mini .virtual-board * {
    pointer-events: none !important;
}

.player-game-sections-mini.not-focused {
    position: absolute;
    opacity: 0;
    top: 0;
    pointer-events: none;
}

.player-game-sections-mini .player-game-section>div:first-child:hover {
    opacity: 0.5;
}

.player-game-sections-mini .player-game-section>div:first-child {
    width: 64vh !important;
    height: 44vh !important;
    transform: scale(0.25);
    transform-origin: top left;
    overflow: hidden;
    border-radius: calc(var(--player-game-section-border-radius) * 4);
    transition: opacity 0.15s;
    cursor: pointer;
}

.player-game-sections-mini .player-game-section>div:first-child * {
    pointer-events: none;
}

.player-game-sections-mini .player-game-section {
    height: 11vh;
    margin-top: 0.5vh;
    margin-bottom: 0.5vh;
}

.player-game-sections-mini .player-game-section .game-section .virtual-board {
    --view-height: 44vh !important;
}

.player-game-sections-mini .player-game-section.focused {
    opacity: 0.5;
    pointer-events: none;
}

.player-0 {
    order: 1;
}

.player-1 {
    order: 2;
}

.player-2 {
    order: 4;
}

.player-3 {
    order: 3;
}

.player-game-section {
    height: 100%;
    width: 100%;
    border-radius: var(--player-game-section-border-radius);
    overflow: visible;
    position: relative;
    background: var(--secondary-background-color);
}

.player-game-section .mode-selection {
    width: 100%;
    height: 100%;
    background: url('../../../Home/background-2.jpg');
    background-size: cover;
    background-position: center;
    border-radius: var(--player-game-section-border-radius);
    padding: 0%;
}

.player-game-section .mode-selection .selection-panel {
    border-radius: var(--player-game-section-border-radius);
    /*background-color: var(--secondary-background-color);*/
    height: 100%;
    width: 100%;
    backdrop-filter: blur(1.75vh);
    overflow-y: auto;
    overflow-x: hidden;
}

.player-game-section .mode-selection .selection-panel>div {
    padding: 10vh;
    min-height: 100%;
}

.mode2v2 .player-game-section .mode-selection .selection-panel>div {
    padding: 2vh;
}

.player-game-section .mode-selection .button {
    width: 100%;
    height: 12vh;
    margin: 1vh;
}

.player-game-section .mode-selection .cancel-button {
    position: absolute;
    top: 7vh;
    left: 7vh;
    width: 10vh;
    height: 4vh;
}

.mode2v2 .player-game-section .mode-selection .cancel-button {
    top: 1vh;
    left: 1vh;
}

.player-game-section .mode-selection .QRCode {
    width: 25vh;
}

.player-game-sections.focused .player-game-section.not-focused {
    margin: var(--player-sections-gap);
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    z-index: 0;
    grid-area: 1 / 1 / 2 / 2;
}

.player-game-sections-mini .top-bar {
    opacity: 0 !important;
}

.player-game-section:hover .top-bar>* {
    opacity: 1 !important;
    transition: opacity 0.15s;
}

.player-game-section .top-bar>*:not(.keep-visible) {
    opacity: 0.5;
}

.player-game-section .top-bar {
    width: 100%;
    height: var(--top-bar-height);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    padding: 0.5vh;
    padding-left: 5.5vh;
    border-top-left-radius: var(--player-game-section-border-radius);
    border-top-right-radius: var(--player-game-section-border-radius);
    --main-font-size: 1.75vh;
}

.player-game-section .top-bar p,
.player-game-section .top-bar .button {
    margin: 0 0.5vh;
}

.player-game-section .top-bar .button {
    height: calc(var(--top-bar-height) - 1vh) !important;
}

.player-game-section .top-bar p {
    font-weight: var(--font-weight-semi-bold);
}

.player-game-section .top-bar .button img {
    margin: 0.2vh !important;
    width: calc(100% - 0.4vh);
    height: calc(100% - 0.4vh);
}

.player-game-section.is-active-player {
    outline: 0.3vh solid var(--main-color);
}

.player-game-section.is-first-player .first-player-image {
    opacity: 1;
    pointer-events: all;
}

.first-player-image {
    width: 6vh;
    height: 6vh;
    position: absolute;
    top: -1vh;
    left: -0.5vh;
    border-radius: 50%;
    opacity: 0;
    z-index: 4;
    pointer-events: none;
}

.first-player-image img {
    width: 100%;
    height: 100%;
    pointer-events: all;
}

.first-player-image .button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.8);
    opacity: 0;
}

.first-player-image:hover .button {
    opacity: 1;
}

.player-game-section .game-section {
    /*aspect-ratio: 13/9;*/
    width: 100%;
    padding-top: var(--top-bar-height);
}

.player-game-sections-mini .player-game-section .game-section {
    padding-top: 0 !important;
}



.player-game-section .video {
    height: 100%;
    border-radius: var(--player-game-section-border-radius);
    overflow: hidden;
}

.player-game-section .video>div  {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.player-game-section .video video {
    object-fit: contain;
    height: fit-content !important;
    max-height: 100% !important;
    width: fit-content !important;
    max-width: 100% !important;
    min-width: 100%;
    min-height: 100%;
    display: block;
}

.player-game-section .video.flip-horizontal {
    transform: scaleX(-1);
}

.player-game-section .video.flip-vertical {
    transform: scaleY(-1);
    transform-origin: center;
}

.player-game-section .video.flip-horizontal.flip-vertical {
    transform: scaleY(-1) scaleX(-1);
}












.player-game-section .virtual-board {
    margin: 0;
    width: 100%;
    /* FOR 4 PLAYERS */
    /*--view-height: calc(var(--game-sections-height) / 2 - 1.5vh) !important;*/
    --view-height: calc(var(--game-sections-height) - var(--player-sections-gap) * 2 - var(--top-bar-height)) !important;
    overflow: hidden;
    border-radius: var(--player-game-section-border-radius) !important;
    height: var(--view-height);
    /*z-index: 90001;*/
    --pile-count-font-size: 4vh;
}

.player-game-section.your-section .virtual-board {
    /*z-index: 90002;*/
}

.mode2v2 .player-game-section .virtual-board {
    --view-height: calc((var(--game-sections-height)) / 2 - var(--top-bar-height) + 0.5vh - var(--player-sections-gap) * 2) !important;
}

.mode2v2 .player-game-sections.focused .virtual-board {
    --view-height: calc(var(--game-sections-height) - var(--top-bar-height) - var(--player-sections-gap) * 2) !important;
}

.player-game-section.focused.your-section .virtual-board .deck,
/*.player-game-section.focused.your-section .virtual-board .hand *, */
.player-game-section.focused.your-section .virtual-board .mana {
    pointer-events: all !important;
}

.player-game-sections-mini .hand {
    scale: 0.6;
}


.player-game-section.your-section .virtual-board .left-bar,
.player-game-section.your-section .virtual-board .left-bar-placeholder {
    display: none !important;
}

.player-game-section .discard-panel {
    position: absolute;
}

.player-game-section .fullscreen-panel {
    /*z-index: 900001;*/
}

.player-game-section .virtual-board .board .board-card .tokens div p {
    font-size: 1.5vh;
}

.player-game-section  .virtual-board .tokens-row {
    padding: 0 0vh;
}



.QRCodeFound .player-game-section.your-section .QRCode-found-feedback {
    opacity: 1;
}

.QRCode-found-feedback {
    width: 100% !important;
    height: 100% !important;
    top: 0;
    left: 0;
    background-color: white;
    position: absolute;
    opacity: 0;
    transition: opacity 0.3s;
    border-radius: var(--player-game-section-border-radius);
    pointer-events: none;
}

/* DICE ROLLS */

.dice-roll-results {
    position: absolute;
    top: 2vh;
    left: 50%;
    transform: translate(-50%, 0);
    /*z-index: 90100;*/
    z-index: 5;
    pointer-events: none;
}

.dice-roll-results>div {
    width: 20vh;
    position: relative;
    margin-left: 1vh;
    margin-right: 1vh;
}

.dice-roll-results p {
    background-color: rgba(0, 0, 0, 0.625);
    backdrop-filter: blur(1vh);
    color: white;
    border-radius: var(--main-ui-border-radius);
    padding: 0.5vh 2vh;
    margin-bottom: 1vh;
    font-weight: var(--font-weight-semi-bold);
    animation: dice-result-anim 5s forwards;
    border: 1px solid rgba(128, 128, 128, 0.576) !important;
}

@keyframes dice-result-anim {
    0% {
        opacity: 0;
    }

    5% {
        opacity: 1;
    }

    80% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        display: none !important;
    }
}

.zoomed-video .video-wrapper {
    transform: scale(4);
    transform-origin: top left;
}

.video.zoomable>div {
    cursor: none;
}

.video .target {
    width: 100%;
    height: 100%;
    transform: scale(0.25) translate(-150%, -150%);
    border: 0.8vh solid rgba(255, 255, 255, 0.724);
    border-radius: var(--main-ui-border-radius);
    position: absolute;
    z-index: 2;
    pointer-events: none;
}

.zoomed-video .target {
    display: none;
}

