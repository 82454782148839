.user-info {
    background-color: var(--grey-background-color);
    border-radius: var(--main-ui-border-radius);
    margin-top: 0.5vh;
    margin-bottom: 0.5vh;
    padding: 1vh 1vh;
    height: 6vh;
}

.user-info .team-icon {
    height: 100%;
    object-fit: contain;
}

.user-info p {
    margin: 0;
    padding: 0.5vh;
}

.user-info.you p {
    color: var(--main-color) !important;
    font-weight: var(--font-weight-semi-bold);
}

.lobby {
    background-color: var(--grey-background-color);
    border-radius: var(--main-ui-border-radius);
    padding: 2vh;
    margin-top: 2vh;
    margin-bottom: 2vh;
}

.lobby:first-child {
    margin-top: 1vh;
}

.lobby>.d-flex:last-child {
    margin-top: 2vh;
}

.lobbies {
    margin-top: 2vh;
    height: 40vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 2vh;
}

.lobby .user-name {
    margin: 0;
}

.user-turn {
    background-color: var(--grey-background-color);
    height: 4vh;
    width: 4vh;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.9vh;
}