:root {
  --main-color: #d4af37;
  --main-color-transparent: #e4bc38a7;
  --grey-background-color: #efeeec;
  --white-background-color: white;
  /*--secondary-background-color: rgb(238, 236, 226);*/
  --secondary-background-color: #eeebe5;
  --transparent-background: #f6f4f0ae;
  --background-color: #f9f7f3;
  --img-brightness: 0.2;
  --main-ui-border-radius: 1.5vh;
  --small-font-size: 1.3vh;
  --main-font-size: 1.6vh;
  --main-font-size-subtitle: 2.5vh;
  --font-weight-semi-bold: 600;
  --font-weight-regular: 400;
  --main-blur: 1vh;
  --card-size-ratio: 1.4;
  --text-color: rgb(41, 41, 32);
  --navigation-font-size: 2vh;
  --card-border-radius-height-ratio: 0.05;
  --shadow-color: rgba(0, 0, 0, 0.109);
}

[data-theme="dark"] {
  --main-color-transparent: #3e3d37a7;
  /*--secondary-background-color: #272726;
  --background-color: #2d2d2a;*/
  --secondary-background-color: #2d2d2a;
  --background-color: #272726;
  --transparent-background: #272726ae;
  --grey-background-color: #313030;
  --text-color: white;
  --img-brightness: 0.9;
  --white-background-color: #3f3f3f;
  --shadow-color: rgba(0, 0, 0, 0.228);
}

img {
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  pointer-events: none;
  -webkit-touch-callout: none;
}

* {
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-touch-callout: none;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: var(--background-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: "Inter";
  /*Can be any text*/
  src: local("Inter"),
    url("./Fonts/Inter.ttf") format("truetype");
}

body {
  font-family: "Inter";
  max-width: 100%;
  overflow-x: hidden;
}

body:has(.game),
body:has(.virtual-board) {
  max-height: 100%;
  overflow-y: hidden;
}

button,
a,
.button {
  border: 0;
  background-color: var(--main-color);
  border-radius: 0.5vh;
  /*border-top-left-radius: 1rem;
  border-bottom-right-radius: 1rem;*/
  font-size: var(--main-font-size);
  padding: 0.7vh !important;
  transition: all 0.2s;
  font-weight: var(--font-weight-semi-bold);
  color: black;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 4vh;
  margin-top: auto;
  margin-bottom: auto;
  cursor: pointer;
}

button,
.button,
button.no-shadow:hover,
.button.no-shadow:hover,
.buttons a:hover,
.buttons a.selected,
.button.tertiary.selected {
  box-shadow: 0px 4px 8px 0px rgba(16,24,64,.08),
  0px 4px 8px 0px rgba(90, 80, 20, 0.08);
}

button.no-shadow,
.button.no-shadow,
.button.tertiary.no-shadow:hover {
  box-shadow: none;
}

button.primary *,
a.primary *,
.button.primary * {
  color: black;
}


.button.wide,
button.wide,
a.wide {
  min-width: 18vh;
}

button:hover,
a:hover,
.button:hover {
  /*border-top-left-radius: 3px;
  border-bottom-right-radius: 3px;*/
  background-color: rgb(231, 204, 87) !important;
}

.button.unselected,
button.unselected,
a.unselected {
  opacity: 0.7;
}

.button img,
button img {
  width: calc(100% - 1vh);
  height: calc(100% - 1vh);
  filter: brightness(var(--img-brightness));
  margin: 0.5vh;
}

.button:hover img,
button:hover img {
  filter: brightness(var(--img-brightness)) !important;
}

button.secondary,
.button.secondary,
button.tertiary,
.button.tertiary {
  background-color: transparent;
  color: var(--text-color);
}

button.selected,
.button.selected {
  background-color: var(--main-color);
}

button.secondary.selected,
.button.secondary.selected {
  background-color: transparent;
}

button.secondary.selected img,
.button.secondary.selected img {
  filter: brightness(0.5);
}

button.tertiary:hover,
.button.tertiary:hover {
  /*transform: scale(0.9);*/
  text-decoration: underline;
  background-color: transparent !important;
}

button.tertiary.selected:hover,
.button.tertiary.selected:hover {
  /*transform: scale(1);*/
  text-decoration: underline;
  background-color: var(--main-color) !important;
}

p {
  font-size: var(--main-font-size);
  font-weight: var(--font-weight-regular);
  color: var(--text-color);
}

p img {
  filter: brightness(var(--img-brightness));
  width: var(--main-font-size);
  height: var(--main-font-size);
  margin-left: 0.5vh;
  margin-right: 0.5vh;
}

h1 {
  font-size: min(10vh, 15vw);
  font-weight: var(--font-weight-regular);
  color: var(--text-color);
}

.bold {
  font-weight: 800;
}

h2 {
  font-weight: var(--font-weight-semi-bold);
  font-size: var(--main-font-size-subtitle);
  color: var(--text-color);
}

h3 {
  font-weight: var(--font-weight-semi-bold);
  font-size: var(--main-font-size);
  text-align: left;
  color: var(--text-color);
}

.shadowed {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0.5vh 2vh 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.shadowed-light {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 0.1vh 0.5vh 0px;
}

.min-height-100 {
  min-height: 100vh;
}

.app-icon {
  padding: 2vh;
  /*filter: brightness(0);*/
}

.box-container {
  /*background-color: var(--grey-background-color);*/
  padding: 8vh;
  border-radius: var(--main-ui-border-radius);
}

.row:before,
.row:after {
  width: 0px;
  height: 0px;
}

.row:before,
.row:after {
  display: flex !important;
}

.portrait-only h3 {
  text-align: center !important;
  padding: 10vw;
}

@media (orientation: portrait) {
  .landscape-only {
    display: none !important;
  }
}

@media (orientation: landscape) {
  .portrait-only {
    display: none !important;
  }
}

.description {
  background-color: var(--secondary-background-color);
  padding-top: 5vh;
  padding-bottom: 5vh;
  padding-left: 10vw;
  padding-right: 10vw;
}

.description p {
  font-weight: var(--font-weight-semi-bold);
}

p a {
  background-color: transparent;
  color: var(--main-color) !important;
  height: fit-content;
  padding: 0 !important;
}

p a:hover {
  background-color: transparent !important;
  opacity: 0.8;
  text-decoration: underline;
}

.accordion-item,
.accordion-item * {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}

.accordion-item button h2 {
  color: black;
}

.accordion-item button.collapsed h2 {
  color: var(--text-color)
}

.accordion-item .accordion-collapse {
  background-color: var(--white-background-color);
}

.accordion-item button {
  background-color: var(--main-color) !important;
}

.accordion-item button.collapsed:hover {
  background-color: var(--main-color) !important;
}

.accordion-item button.collapsed:hover h2 {
  color: black !important
}

.accordion-item button.collapsed {
  background-color: var(--secondary-background-color) !important;
}

.flex-row.input-with-button {
  background-color: var(--grey-background-color);
  border-radius: var(--main-ui-border-radius);
  padding: 1vh;
  margin-bottom: 2vh;
}

.flex-row.input-with-button p,
.flex-row.input-with-button input {
  font-weight: var(--font-weight-semi-bold);
  color: var(--text-color)
}

.flex-row.input-with-button input::placeholder {
  color: rgba(128, 128, 128, 0.483);
}

.flex-row.flex-row.input-with-button p,
.flex-row.input-with-button input {
  margin-bottom: 0;
  margin-right: 1vh;
  border: 0;
  background-color: var(--grey-background-color);
  font-size: var(--small-font-size);
}

.blurred-background {
  /*background-color: var(--white-background-color);*/
  background-color: var(--secondary-background-color);
  opacity: 0.8;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}


/*---------------- PATREON ----------------*/

.patreon {
  border-radius: 1vh;
  padding: 0.5vh 1vh !important;
  width: fit-content;
  --uncommon: rgb(185, 220, 235);
  --alt-uncommon: rgb(70, 100, 110);
  --rare: rgb(230, 205, 140);
  --alt-rare: rgb(118, 98, 55);
  --mythic: rgb(245, 145, 5);
  --alt-mythic: rgb(180, 50, 25);
  --shifted: rgb(195, 155, 200);
  --alt-shifted: rgb(100, 40, 120);
}

.patreon-0 {
  font-weight: var(--font-weight-semi-bold);
  /*text-shadow: 1px 1px 15px black;*/
}

.patreon-1 {
  background: linear-gradient(45deg, var(--alt-uncommon), var(--uncommon), var(--alt-uncommon));
  font-weight: var(--font-weight-semi-bold);
}

.patreon-2 {
  background: linear-gradient(45deg, var(--alt-rare), var(--rare), var(--alt-rare));
  font-weight: var(--font-weight-semi-bold);
}

.patreon-3 {
  background: linear-gradient(45deg, var(--alt-mythic), var(--mythic), var(--alt-mythic));
  font-weight: var(--font-weight-semi-bold);
}

.patreon-4 {
  background: linear-gradient(45deg, var(--alt-shifted), var(--shifted), var(--alt-shifted));
  font-weight: var(--font-weight-semi-bold);
}




.switch {
  width: 100%;
  background-color: var(--grey-background-color);
  position: relative;
  padding: 1vh;
  height: 6vh;
  border-radius: var(--main-ui-border-radius);
}

.switch .button {
  position: absolute;
  left: 1vh;
  top: 1vh;
  transition: left 0.3s;
  width: 12vh;
}

.switch p {
  width: 12vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  cursor: pointer;
}

.switch.enabled .button {
  left: calc(100% - 13vh);
}

.hide-scrollbar::-webkit-scrollbar,
.hide-scrollbar::-webkit-scrollbar,
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar,
.hide-scrollbar,
.hide-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}


.pop-up-message {
  background: var(--secondary-background-color);
  border-radius: var(--main-ui-border-radius);
  z-index: 1000000;
  transform: translate(-50%, 1vh);
  padding: 1.5vh;
  transition: top 0.3s;
  border: 1px solid rgba(0, 0, 0, 0.243);
}

[data-theme="dark"] .pop-up-message {
  border: 1px solid rgba(255, 255, 255, 0.243);
}

.pop-up-message * {
  pointer-events: all;
}

.pop-up-message .close-button {
  filter: brightness(var(--img-brightness));
  width: 3.5vh;
  height: 3.5vh;
  margin-left: 2vh;
  cursor: pointer;
}

.pop-up-message .close-button:hover {
  opacity: 0.7;
}

.pop-up-message.hide {
  top: -200% !important;
}

.box-blurred {
  backdrop-filter: blur(2vh);
  background-color: var(--transparent-background);
  margin: 0;
  padding: 0 1vh;
  overflow: hidden;
}



.animated-glow {

}



.art-deco::after {
  --art-deco-color: var(--background-color); 
  --art-deco-padding: 2vh;
  --art-deco-border-size: 0.25vh;
  content: '';
  width: calc(100% - var(--art-deco-padding) * 2);
  height: calc(100% - var(--art-deco-padding) * 2);
  position: absolute;
  top: var(--art-deco-padding);
  left: var(--art-deco-padding);
  border: 15px solid transparent; /* invisible space */
  outline: var(--art-deco-border-size) solid var(--art-deco-color);
  outline-offset: -10px; /* first box */
  background: linear-gradient(var(--art-deco-color) 0 0) top,
       linear-gradient(var(--art-deco-color) 0 0) left, linear-gradient(var(--art-deco-color) 0 0) bottom,
       linear-gradient(var(--art-deco-color) 0 0) right;
  background-size: 200% var(--art-deco-border-size), var(--art-deco-border-size) 200%; /* second box */
  background-origin: padding-box;
  background-repeat: no-repeat;
}

.vertical-divider {
  width: 0 !important;
  max-width: 0 !important;
  min-width: 0 !important;
  padding: 0 !important;
  min-height: 100%;
  position: relative;
}

.vertical-divider:after {
  --art-deco-color: rgb(202, 154, 34); 
  width: 0.4vh;
  height: 100%;
  background: linear-gradient(transparent 0%, transparent 5%, var(--art-deco-color) 15%, var(--art-deco-color)85%, transparent 95%, transparent 100%);
  position: absolute;
  content: '';
  top: -0%;
  left: -0.2vh;
}

.gray-box {
  background-color: var(--grey-background-color);
  margin: 1vh;
  padding: 2vh;
  border-radius: var(--main-ui-border-radius);
}

.light-shadowed {
  box-shadow: 0vh 1.5vh 4vh var(--shadow-color);
}

.team-A-background {
  background: linear-gradient(45deg, rgba(131, 145, 250, 0.376) 10%, rgba(131, 145, 250, 0.276) 50%);
}

.team-B-background {
  background: linear-gradient(45deg, rgba(198, 52, 33, 0.376) 10%, rgba(198, 52, 33, 0.276) 50%);
}