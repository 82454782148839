.digital-only {
    --tumulte-height: 14vh !important;
    /*transform: rotate3d(1,0,0, 5deg) scale(1.05);*/
}

/*
.virtual-board .hand .shadowed {
    box-shadow: rgba(0, 0, 0, 0.8) 0 0.5vh 2vh 0vh;
}
*/
.game {
    /*perspective: 1000px;*/
}

.use-mini-cards .show-mana-panel {
    display: flex !important;
    pointer-events: all !important;
}

/*
.digital-only .zoomed-card {
    position: fixed !important;
    left: 1vh !important;
    top: 50% !important;
}
*/
.digital-only .top-section {
    position: absolute;
    top: calc(50% - var(--tumulte-height) / 2);
    pointer-events: none;
    backdrop-filter: blur(3vh);
}

.digital-only .player-tokens-area .token-move-buttons {
    top: 0.75vh;
}

.digital-only .player-tokens-area .token-move-buttons>div {
    left: -8vh;
}

.digital-only .player-tokens-area .token-move-buttons>div img {
    width: 2vh;
    height: 2vh;
}

.digital-only .player-token.opponent {
    top: 1.6vh !important;
}

.digital-only .player-tokens-area {
    pointer-events: none;
}

.digital-only .player-token,
.digital-only .power-counters {
    pointer-events: all;
}

.digital-only .power-counters {
    left: 50%;
    width: 0;
    margin: 0;
}

.digital-only .power-counters .power-counter {
    z-index: 1;
    margin-left: -48vh;
}

.digital-only .power-counters .power-counter.companion {
    margin-right: -48vh;
    margin-left: auto;
}

.digital-only .virtual-board {
    position: absolute;
    left: 0;
    top: 0;
    margin: 0;
    width: 100%;
    --view-height: 55vh !important;
    --bottom-section-height: 10vh !important;

    --card-height: calc((var(--view-height) - var(--bottom-section-height)) / 2.5 - 1vh - var(--border-width)) !important;
    --card-width: calc(var(--card-height) / var(--card-size-ratio)) !important;
    --section-height: calc(var(--card-height) + 1vh + var(--border-width)) !important;
}

.digital-only .virtual-board .background-mask {
    -webkit-mask-image: linear-gradient(to bottom, transparent 0%, transparent 3vh, black 5vh);
    mask-image: linear-gradient(to bottom, transparent 0%, transparent 3vh, black 5vh);
}

.digital-only .virtual-board.opponent .background {
    transform: scaleX(-1);
}

.digital-only .virtual-board .background {
    background-position: 50% 40%;
}

.digital-only .virtual-board .board {
    padding-top: calc(var(--tumulte-height) / 2 + 5.5vh);
    padding-left: 30vh;
    padding-right: 30vh;
}

.digital-only .virtual-board .board-section {
    padding: 1.5vh;
    margin: 0.25vh;
    z-index: auto !important;
}




.digital-only .virtual-board .board-section.landmarks {
    margin-right: -28vh !important;
    margin-left: 10vh;
}


.digital-only .virtual-board.opponent>div {
    transform: scaleY(-1);
}

/*
.digital-only .virtual-board.opponent .mana .card-image {
    transform: scaleX(1) !important;
}
*/
.digital-only .virtual-board.your-section {
    top: auto;
    bottom: 0;
}

.digital-only .virtual-board .tokens-panel {
    z-index: 90;
    top: 12vh;
}

.digital-only .virtual-board .tokens-button {
    margin-right: 1vh;
    margin-top: 1vh;
}

.digital-only .virtual-board.spectator .tokens-button {
    display: none;
}

.digital-only:has(.virtual-board.spectator) .top-section * {
    pointer-events: none !important;
}

.digital-only .virtual-board.spectator .focus-card-button {
    display: none;
}





.digital-only .virtual-board .hero {
    margin: 0.5vh 2vh;
    padding: 0 !important;
}

.digital-only .virtual-board .deck {
    position: absolute;
    left: calc(var(--card-width) + 3vh);
    bottom: 3vh;
    padding: 0;
}

.digital-only .virtual-board.opponent .deck {
    transform: scaleY(-1);
    padding: 0 !important;
}

.digital-only .virtual-board .deck .deck-background {
    z-index: 0;
}

.digital-only .virtual-board.opponent .deck .pile-count {
    transform: scaleY(-1);
}

.digital-only .virtual-board.opponent .deck .deck-top {
    transform: scaleY(-1);
}

.digital-only .virtual-board .discard {
    position: absolute;
    left: 1vh;
    bottom: 3vh;
    padding: 0;
    margin-top: 0.5vh;
}

.digital-only .virtual-board.opponent .discard .old-discard {
    scale: 1 -1;
}

.digital-only .virtual-board.opponent .discard .pile-count {
    transform: scaleY(-1);
}

.use-mini-cards.virtual-board .mana {
    position: absolute;
    left: 1vh;
    top: 12.5vh;
    width: 17vh;
    height: var(--section-height);
    opacity: 1;
    background-color: transparent;
    box-shadow: none;
}

.use-mini-cards.virtual-board .waiting-selection .mana {
    background-color: #5871A6;
}

.use-mini-cards.virtual-board.opponent .mana {
    background-color: transparent;
    box-shadow: none;
}

.use-mini-cards.virtual-board .mana .mana-change {
    display: flex !important;
    pointer-events: all !important;
}

.use-mini-cards.virtual-board .mana .mana-change .button {
    width: 50%;
    margin: 0.5vh;
    height: 3.25vh;
}

.use-mini-cards.virtual-board .mana .tapped-wrapper {
    position: absolute;
    top: 0;
    left: 0;
}

.use-mini-cards.virtual-board.opponent .mana .tapped-wrapper {
    top: 15vh;
}

.use-mini-cards.virtual-board .mana .board-card {
    display: none;
}

.use-mini-cards.virtual-board .mana .mana-count {
    width: calc(100% - 1vh);
}

.digital-only .virtual-board.opponent .mana .mana-count {
    transform: scale(1, -1);
}

.use-mini-cards.virtual-board .mana .mana-count>p {
    text-shadow: none;
}

.use-mini-cards.virtual-board.spectator .mana .mana-count .button {
    display: none;
}

.use-mini-cards.virtual-board.spectator .board-card {
    pointer-events: none !important;
}

.virtual-board.spectator .hand .tapped-wrapper {
    pointer-events: all !important; 
}

.virtual-board.spectator .hand .tapped-wrapper:hover {
    transform: none;
    scale: 1 !important;
    top: 0 !important;
    filter: none !important;
    flex: 1 1 !important;
    margin: 0 !important;
}

.virtual-board.spectator .hand .tapped-wrapper:last-child:hover {
    flex: 5 1 !important;
}



.add-mana-animation {
    display: none;
    pointer-events: none;
}

.use-mini-cards .add-mana-animation {
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    width: var(--card-width);
    height: var(--card-height);
    z-index: 3;
    transform: translate(-50%, -50%);
    animation: 2s adding-mana forwards;
    transform-origin: top left;
    opacity: 0;
}

.use-mini-cards.opponent .add-mana-animation,
.use-mini-cards.spectator .add-mana-animation {
    animation: 2s adding-mana 0.5s forwards;
}

@keyframes adding-mana {
    0% {
        top: 50%;
        width: var(--card-width);
        height: var(--card-height);
        opacity: 0.5;
        rotate: 0deg;
    }

    50% {
        opacity: 0;
        rotate: 180deg;
    }

    100% {
        top: 10%;
        width: 0;
        height: 0;
        opacity: 0;
    }
}

/*
.digital-only .virtual-board .mana .scrollable .tapped-wrapper {
    max-width: 3vh !important;;
    max-height: 3vh;
    min-width: 3vh !important;;
    min-height: 3vh;
    overflow: hidden;
    border-radius: 50%;
}*/











/* MINI CARD */
/*
.digital-only .board-section.hero-expedition .scrollable,
.digital-only .board-section.companion-expedition .scrollable {
    justify-content: center !important;
}*/

.digital-only .board-section.hero-expedition {
    margin-left: -10vh;
}

.digital-only .board-section.companion-expedition {
    margin-right: -10vh;
}

.digital-only .board-section.reserve,
.digital-only .board-section.landmarks,
.digital-only .board-section.hero-expedition,
.digital-only .board-section.companion-expedition,
.digital-only .board-section.hero {
    --card-height: calc((var(--view-height) - var(--bottom-section-height)) / 2.5 - 3vh - var(--border-width)) !important;
    --card-width: 14vh !important;
    --section-height: calc(var(--card-height) + 3vh + var(--border-width)) !important;
}

.digital-only .mini-cards .tapped-wrapper {
    /*max-width: 15vh !important;*/
}

.digital-only .mini-cards:not(.hero) .tapped-wrapper.COMMON .board-card .drag-wrapper:after {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    border: 0.5vh solid rgb(23, 23, 23);
    border-radius: 0.8vh;
}

.use-mini-cards .mini-cards .board-card,
.use-mini-cards.virtual-board .reserve .missing-mana .board-card {
    overflow: hidden;
    border-radius: 0.8vh;
    box-shadow: 0px 0vh 0.75vh 0.15vh rgba(0, 0, 0, 0.65);
    /*outline: 0.25vh solid rgb(49, 49, 49);*/
    /* -webkit-box-shadow: 0px 0px 0.4vh 0.2vh rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 0.4vh 0.2vh rgba(0, 0, 0, 0.5);*/
}

.use-mini-cards .mini-cards:not(.hero) .tapped .board-card {
    transform: rotate(7deg) translateY(-1.2%) !important;
}

.use-mini-cards .mini-cards:not(.hero) .tapped .board-card .card-image {
    filter: grayscale(100%) brightness(80%);
}

.use-mini-cards.reserve .mini-cards .board-card {
    outline: 0.25vh solid var(--main-color);
    box-shadow: 0 0 1.25vh 0.6vh var(--main-color);
}

.use-mini-cards .mini-cards .tapped-wrapper.EXPEDITION_PERMANENT  .card-image,
.use-mini-cards .mini-cards .tapped-wrapper.PERMANENT  .card-image {
    object-fit: cover;
    scale: 2.5 !important;
    translate: -0% 68%;
}

.use-mini-cards .mini-cards .tapped-wrapper .card-image {
    object-fit: cover;
    scale: 2 !important;
    translate: -0% 40%;
}

.digital-only .opponent .board-section .tapped-wrapper .board-card {
    scale: 1 -1;
}

.use-mini-cards.virtual-board .mini-cards .tokens {
    justify-content: end !important;
    margin-top: 2.5vh;
}

.use-mini-cards.virtual-board .board-section .mini-cards .card-power-and-cost {
    display: flex !important;
    pointer-events: none;
}

.card-power-and-cost .costs div {
    background-color: rgb(70, 97, 157);
    width: 2.5vh;
    height: 2.5vh;
    border-radius: 50%;
    margin-bottom: 2vh;
    box-shadow: 0px 0px 0.4vh 0.2vh rgba(255, 255, 255, 0.75) inset;
    -webkit-box-shadow: 0px 0px 0.4vh 0.2vh rgba(255, 255, 255, 0.75) inset;
    -moz-box-shadow: 0px 0px 0.4vh 0.2vh rgba(255, 255, 255, 0.75) inset;
}

.card-power-and-cost .costs div:nth-child(2) {
    position: absolute;
    top: 1.5vh;
    left: 1.5vh;
    background-color: rgb(25, 45, 84);
}

.card-power-and-cost p {
    margin: 0;
    color: white;
    font-weight: var(--font-weight-semi-bold);
    font-size: 1.7vh;
}

.card-power-and-cost .pow {
    border-radius: 0.5vh;
    width: 3.5vh;
    height: 2.5vh;
    box-shadow: 0px 0px 0.4vh 0.2vh rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: 0px 0px 0.4vh 0.2vh rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0px 0px 0.4vh 0.2vh rgba(0, 0, 0, 0.25);
    margin-top: 0.2vh;
}

.card-power-and-cost .pow.boosted p {
    font-weight: 1000;
    text-shadow: 0 0 0.8vh black;
}

.card-power-and-cost .pow:nth-child(1) {
    background-color: #9EB042;
    border-top-left-radius: 1vh;
    border-top-right-radius: 1vh;
}

.card-power-and-cost .pow:nth-child(2) {
    background-color: #BD8049;
}

.card-power-and-cost .pow:nth-child(3) {
    background-color: #5871A6;
    border-bottom-left-radius: 1vh;
    border-bottom-right-radius: 1vh;
}

.card-power-and-cost .pow.no-value {
    opacity: 0;
}
















.digital-only .virtual-board .hand {
    top: -3.5vh;
    --focus-top: -15vh;
}

.digital-only .virtual-board.opponent .hand {
    top: -3.5vh !important;
}




.digital-only .top-bar {
    background-color: rgba(224, 224, 224, 0.499);
    backdrop-filter: blur(var(--main-blur));
    position: absolute;
    bottom: 1vh;
    right: 3vh;
    z-index: 100;
    padding: 0.8vh;
    border-radius: var(--main-ui-border-radius);
}

[data-theme="dark"] .digital-only .top-bar {
    background-color: rgba(51, 51, 51, 0.499);
}


.digital-only .first-player-image {
    position: absolute;
    right: 10vh;
    left: auto;
    top: 60%;
    opacity: 1;
    z-index: 10;
    transition: top 0.3s;
    transform: translateY(-50%);
}

.digital-only .first-player-image.opponent {
    top: 40%;
}

.digital-only .first-player-image * {
    pointer-events: all;
}

.digital-only .virtual-board .board-card .tokens div p {
    font-size: 1.6vh;
}

.digital-only .virtual-board .hand {
    max-width: 105%;
}

.digital-only .virtual-board .discard-panel,
.digital-only .virtual-board .card-focused {
    z-index: 90000 !important;
    height: 100vh !important;
    margin-top: -45vh;
    padding-top: 2vh;
}

.digital-only .virtual-board.opponent:has(.discard-panel) {
    z-index: 200;
}

.digital-only .virtual-board.opponent .discard-panel {
    transform: rotate(0deg);
    margin-top: 0vh;
}

.digital-only .virtual-board.opponent .discard-panel .card-image {
    transform: scaleX(1);
}

.digital-only .digital-user-name {
    position: absolute;
    right: 2vh;
    bottom: 25vh;
}

.digital-only .digital-user-name.opponent {
    top: 27vh;
    bottom: auto;
}

.digital-only .evening-section {
    top: -45vh !important;
}


.digital-only .virtual-board .quick-token-selection-info {
    z-index: 5;
}

.digital-only .digital-dice-rolls {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.digital-only .digital-dice-rolls .player {
    margin-top: 50vh;
    position: relative;
}

.digital-only .digital-dice-rolls .opponent {}

.digital-only .time-travel-warning {
    top: 50% !important;
    border-radius: var(--main-ui-border-radius) !important;
}

.digital-only .opponent .time-travel-warning {
    scale: 1 -1;
}