:root {
    --left-side-width: 6vh;
    --right-side-width: 15vw;
    /*--central-section-width: calc(100vw - var(--right-side-width));*/
    --tumulte-height: 17vh;
    --webcam-height: calc(100vh - var(--tumulte-height));
    --player-sections-gap: 0.5vh;
    --side-bar-toggle-width: 2vh;
}

.game {
    height: 100vh;
    width: 100vw;
    max-height: 100vh;
    max-width: 100vw;
    overflow: hidden;
    position: relative;
}

.game:focus {
    outline: none;
}

.nope {
    pointer-events: none;
}

.image-wrapper {
    width: fit-content;
    height: fit-content;
}

.central-section {
    height: 100%;
    width: 100%;
    position: relative;
}

.webcam-section {
    max-width: 100%;
    width: fit-content;
    height: calc(100% - 20vh);
    margin-left: auto;
    margin-right: auto;
    padding: 1vh;
}

.webcam-section.maximized,
.webcam-section.maximized .react-player,
.webcam-section.maximized .react-player>div,
.webcam-section.maximized .react-player video {
    width: 100% !important;
    object-fit: cover;
    height: fit-content !important;
}

.webcam-section .react-player,
.webcam-section .react-player>div {
    max-width: 100%;
    width: fit-content;
    /*height: calc(var(--webcam-height) - 2vh) !important;*/
    height: fit-content !important;
    /*margin: 1vh;*/
    max-height: calc(var(--webcam-height) - 2vh) !important;
}

.webcam-section .react-player video {
    max-width: 100% !important;
    /*width: fit-content !important; not working on firefox*/
    /*width: 100% !important;*/
    /*height: calc(var(--webcam-height) - 2vh) !important;*/
    max-height: calc(var(--webcam-height) - 2vh) !important;
    height: calc(var(--webcam-height) - 2vh) !important;
    /*transform: rotate(180deg);*/
    transform-origin: center;
    object-fit: cover;
    /*padding: 1vh;*/
    overflow: hidden;
    object-position: top;
    background-color: black;
    border-radius: calc(var(--main-ui-border-radius) + 1vh);
}

.webcam-section.flip-horizontal video {
    transform: scaleX(-1);
}

.webcam-section.flip-vertical video {
    transform: scaleY(-1);
    transform-origin: center;
}

.maximized.webcam-section.flip-vertical video {
    transform: scaleY(-1);
}

.maximized.webcam-section.flip-vertical video {
    object-position: bottom !important;
}

.webcam-section.flip-vertical.flip-horizontal video {
    transform: scaleY(-1) scaleX(-1);
}

.webcam-image .ReactCrop__image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    overflow: hidden;
}

.game .right-side img {
    width: 100%;
    /*height: fit-content;*/
}





.token-selection .button {
    background: 0;
    border-radius: 4vh;
    height: 8vh;
    width: 8vh;
    margin: 1vh;
    border: 1vh solid transparent;
    padding: 0 !important;
}

.token-selection .button:hover {
    opacity: 0.7;
    border: 1vh solid transparent !important;
}

.token-selection .button:hover img {
    filter: brightness(1) !important;
}

.token-selection .button.selected {
    border: 1vh solid var(--main-color);
}

.token-selection .button img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    filter: brightness(1);
    margin: 0;
}

.webcam-resolution {
    background-color: white;
    padding: 1vh;
    font-size: 2vh;
    display: none;
    position: absolute;
    left: 10vh;
    top: 0;
    z-index: 90001;
}

.debugging .webcam-resolution {
    display: block;
}

.debugging .box-image {
    display: block;
    left: 10vh;
}

.user-stream video {
    border-radius: var(--main-ui-border-radius);
    margin: 2vh;
}

.user-stream>div {
    margin-left: auto;
    margin-right: auto;
}

.user-media-stream {
    position: absolute;
    bottom: 1vh;
    left: 1vh;
    width: 30vh;
    height: fit-content;
    display: flex;
    flex-direction: column-reverse;
    transition: opacity 0.3s;
    z-index: 900;
}

.user-media-stream>div {
    height: fit-content !important;
    margin-bottom: -1vh;
}

.user-media-stream video {
    object-fit: contain;
    border-radius: var(--main-ui-border-radius);
    height: auto !important;
    border: 1px solid black;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0.5vh 2vh 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.first-player-token {
    height: var(--tumulte-height);
    width: 12.5vh;
    position: absolute;
    top: 0;
    right: 5vh;
    z-index: 1;
}

.first-player-token.opponent .token {
    bottom: calc(var(--tumulte-height) - 1vh - 8.5vh);
}

.first-player-token .token {
    position: absolute;
    bottom: 0.5vh;
    width: 8.5vh;
    height: 8.5vh;
    right: 2vh;
    transition: bottom 0.1s linear;
    border-radius: 50%;
}

.first-player-token .token img {
    border-radius: 50%;
    width: 100%;
}

.first-player-token .token button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.3s;
}

.first-player-token .token:hover button {
    opacity: 1;
}

.first-player-token .token:hover p {
    opacity: 0;
}

.first-player-token p {

    backdrop-filter: blur(1vh);
    font-size: var(--small-font-size);
    padding: 0.4vh;
    border-radius: 0.5vh;
    position: absolute;
    width: 100%;
    top: 3vh;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.3s;
    pointer-events: none;
}

.first-player-token.opponent p {
    top: calc(var(--tumulte-height) - 3vh);
}

.webcam-virtual-section {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: minmax(0, 1fr);
    position: relative;
}

.secondary-webcam-section>div {
    margin-left: 0 !important;
    padding-left: 0 !important;
}

.secondary-webcam-section .virtual-board.spectating {
    width: calc(100% - 1vh);
}

/*
.zoomed-wrapper {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 0, 0, 0.371);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 900000 !important;
    pointer-events: none;
}
*/
.zoomed-card {
    /*
    position: fixed !important;
    top: 0;
    right: 0;
    width: var(--right-side-width) !important;
    height: 100vh !important;
    pointer-events: none;
    z-index: 900000 !important;
    background-color: var(--secondary-background-color);*/
    z-index: 9000000000 !important;
    pointer-events: none;
    width: fit-content;
    height: fit-content;
}

.zoomed-card img {
    width: 25vh !important;
    height: calc(25vh * var(--card-size-ratio));
    border-radius: 1vh;
    margin-top: 1vh;
    transform: translate(-130%, -50%);
    pointer-events: none;
    z-index: 900003;
}

.zoomed-card.other-side img {
    transform: translate(30%, -50%);
}

.zoomed-card.focused-card img {
    width: 25vh !important;
    height: calc(25vh * var(--card-size-ratio));
    border-radius: 1vh;
    margin-top: 1vh;
    transform: translate(-50%, -50%);
}

.mobile-mode .zoomed-card.focused-card img {
    width: 25vh !important;
    height: calc(25vh * var(--card-size-ratio));
    border-radius: 1vh;
    margin-top: 1vh;
    transform: translate(10%, -50%);
}

.zoomed-card div {
    transform: translate(-50%, -50%);
    opacity: 0;
    height: calc(25vh * var(--card-size-ratio));
    border-radius: 1vh;
    background-color: rgba(18, 18, 18, 0.283);
    margin-top: 1vh !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.zoomed-card div h2 {
    margin-bottom: 10vh;
    color: white;
}

.virtual-board:has(.board-section.mana:hover) .zoomed-card .mana-info,
.virtual-board:has(.board-section.hero-expedition:hover) .zoomed-card .hero-expedition-info,
.virtual-board:has(.board-section.companion-expedition:hover) .zoomed-card .companion-expedition-info,
.virtual-board:has(.board-section.reserve:hover) .zoomed-card .reserve-info,
.virtual-board:has(.board-section.landmarks:hover) .zoomed-card .landmarks-info,
.virtual-board:has(.board-section.discard:hover) .zoomed-card .discard-info {
    opacity: 1;
}

/*
.zoomed-card>div {
    max-width: 25vh !important;
    width: 25vh !important;
    max-height: calc(25vh * var(--card-size-ratio));
    height: calc(25vh * var(--card-size-ratio));
    border-radius: 1vh;
    margin-top: 1vh;
    background-color: red;
    transform: translate(-450%, -235%);
    position: relative;
    overflow: visible;
}

.zoomed-card>div>div {
    min-width: 100%;
    min-height: 100%;
}
*/



.spectating .tumulte-section {
    pointer-events: none;
}

.spectating .power-counters {
    pointer-events: none;
}

.spectating .glow {
    border: 2px solid transparent !important;
    box-shadow: 0 0 5px transparent !important;
}

.spectating .spectator-hidden {
    display: none !important;
}

.menu-selection {
    min-height: 6vh;
    margin: 0 1vh;
}

.menu-selection .button {
    width: 100%;
}

.game .right-side>.h-100 {
    max-height: calc(100% - 6vh) !important;
}

.zoomed-only {
    animation: zoomed-card-appear 0s forwards;
}

@keyframes zoomed-card-appear {
    0% {
        /*scale: 0.8;*/
        opacity: 0;
    }

    100% {
        /*scale: 1;*/
        opacity: 1;
    }
}

.last-played-card {
    position: absolute;
    right: 5vh;
    top: calc(50% - var(--last-played-height) / 2);
    --last-played-height: calc(25vh * var(--card-size-ratio));
    width: 25vh !important;
    height: var(--last-played-height);
    scale: 0;
    animation: last-played-appear 5s forwards;
    z-index: 11;
}

@keyframes last-played-appear {
    0% {
        scale: 0.5;
        opacity: 0;
    }

    5%,
    90% {
        scale: 1;
        opacity: 1;
    }

    100% {
        scale: 1;
        opacity: 0;
    }
}

.last-played-card img {
    width: 100%;
    height: 100%;
    border-radius: 1vh;
}