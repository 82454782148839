.chat {
    height: 100%;
    position: relative;
}

.hide .chat {
    display: none !important;
}

.unread-notification {
    width: 3vh;
    height: 3vh;
    border-radius: 50%;
    background-color: rgba(165, 44, 44, 0.865);
    position: absolute;
    top: 0.25vh;
    left: -3.25vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.unread-notification p {
    color: white;
    font-weight: var(--font-weight-semi-bold);
}

.message-editor>div {
    margin: 0.25vh !important;
}

.message-editor textarea {
    resize: "none";
    min-height: 2vh;
    margin: 0;
    font-size: var(--main-font-size);
}

.message-editor .button {
    margin-top: auto;
    margin-bottom: 0 !important;
    margin-left: 1vh;
}

.messages {
    height: 100%;
    max-height: 100%;
    width: 100%;
    padding: 0.25vh;
    overflow-y: scroll;
    display: flex;
    flex-direction: column-reverse !important;
}

.chat .top-gradient {
    height: 3vh;
    width: 100%;
    position: absolute;
    background: linear-gradient(180deg, var(--background-color), transparent);
    top: 0;
    left: 0;
    pointer-events: none;
}






.message {
    max-width: 80%;
    width: fit-content;
    margin-bottom: 2vh;
    border-radius: var(--main-ui-border-radius);
    background-color: var(--secondary-background-color);
    padding: 1vh;
    border-bottom-left-radius: 0;
}

.message-sender {
    margin: 0;
    font-size: 1.1vh;
    font-weight: var(--font-weight-semi-bold);
}

.message-content {
    margin: 0;
    font-size: 1.5vh;
    text-align: start;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-word;
    word-break: break-word;
    user-select: text;
}

.message.you * {
    /*color: var(--main-color) !important;*/
    text-align: end;
    margin-left: auto;

}

.message.you {
    margin-left: auto;
    border-bottom-left-radius: var(--main-ui-border-radius);
    border-bottom-right-radius: 0;
}